<template>
  <div>
    <div class="card card-custom gutter-b descricao-card">


      <div class="col-md-12 mt-1" v-show="editarNome">
        <div class="form-group">
          <label>Nome</label>
          <div class="input-group">
            <input type="text" class="form-control" autocomplete="off" maxlength="100" v-model="cliente.razao_social"
              @blur="alterarDadosCadastro('razao_social', cliente.razao_social)" :class="{
                  'is-invalid': submitted && $v.cliente.razao_social.$error,
                }" />
            <div class="input-group-append">
              <button class="btn btn-primary" type="button" @click="() => (editarNome = false)">
                Salvar
              </button>
            </div>
          </div>
          <div v-if="submitted && $v.cliente.razao_social.$error" class="invalid-feedback">
            Preencha o campo corretamente
          </div>
        </div>
      </div>
    </div>
    <div class="card card-custom">
      <h6 class="text-center pt-5 pl-2 pr-2">
        Preencha seus dados pessoais
      </h6>

      <div class="kt-form__section kt-form__section--first card-body">
        <div class="row">

        </div>


        <div class="div-endereco">
          <div class="row">

            <div class="col-md-4 mt-5" v-show="isEmpresa">
              <div class="form-group">
                <label>Nome fantasia</label>
                <input type="text" class="form-control" autocomplete="off" name="nome_fantasia" maxlength="30"
                  v-model="cliente.nome_fantasia" @blur="alterarDadosCadastro('nome_fantasia', cliente.nome_fantasia)
                    " :class="{
      'is-invalid': submitted && $v.cliente.nome_fantasia.$error,
    }" />
                <div v-if="submitted && $v.cliente.nome_fantasia.$error" class="invalid-feedback">
                  Preencha o campo corretamente
                </div>
              </div>
            </div>
            <div class="col-md-4 mt-5" v-show="!isEmpresa">
              <div class="form-group">
                <label>Data de Nascimento</label>
                <TheMask type="tel" class="form-control" id="data_nascimento" name="data_nascimento" :masked="true"
                  autocomplete="off" :mask="['##/##/####']" v-model="cliente.data_nascimento" @blur.native="alterarDadosCadastro(
                      'data_nascimento',
                      cliente.data_nascimento
                    )
                    " :class="{
      'is-invalid': submitted && $v.cliente.data_nascimento.$error,
    }"></TheMask>

                <div v-if="submitted && $v.cliente.data_nascimento.$error" class="invalid-feedback">
                  Preencha o campo corretamente
                </div>
              </div>
            </div>
            <div class="col-md-4 mt-5" v-show="!isEmpresa">
              <div class="form-group">
                <label>Sexo</label>
                <select class="form-control" v-model="cliente.genero"
                  @blur="alterarDadosCadastro('genero', cliente.genero)">
                  <option value="M">Masculino</option>
                  <option value="F">Feminino</option>
                </select>
                <div v-if="submitted && $v.cliente.genero.$error" class="text-danger">
                  Preencha o campo corretamente
                </div>
              </div>
            </div>

            <div class="col-md-4" v-bind:class="{ 'mt-5': isEmpresa, 'mt-1': !isEmpresa }">
              <div class="form-group">
                <label>Email</label>
                <input type="text" class="form-control" autocomplete="off" name="email1" maxlength="100"
                  v-model="cliente.email1" :disabled="cliente.sem_email"
                  @blur="alterarDadosCadastro('email1', cliente.email1)" :class="{
                      'is-invalid': submitted && $v.cliente.email1.$error,
                    }" />
                <div v-if="submitted && $v.cliente.email1.$error" class="invalid-feedback">
                  Preencha o campo corretamente
                </div>
              </div>
            </div>
<!--            <div class="col-md-2 align-self-center">-->
<!--              <label class="checkbox">-->
<!--                <input type="checkbox" v-on:click="disableInputEmail" />-->
<!--                <span class="mr-2"></span> Não tenho email-->
<!--              </label>-->
<!--            </div>-->

            <div class="col-md-4 mt-1">
              <div class="form-group">
                <label>Seu melhor telefone</label>
                <TheMask type="tel" class="form-control" id="telefone1" name="telefone1" autocomplete="off"
                  :mask="['(##) #####-####']" :masked="true" v-model="cliente.telefone1" @blur.native="alterarDadosCadastro('telefone1', cliente.telefone1)
                    " :class="{
      'is-invalid': submitted && $v.cliente.telefone1.$error,
    }">
                </TheMask>
                <div v-if="submitted && $v.cliente.telefone1.$error" class="invalid-feedback">
                  Preencha o campo corretamente
                </div>
              </div>
            </div>
            <div class="col-md-4 mt-1">
              <div class="form-group">
                <label>Telefone (Opcional)</label>
                <TheMask type="tel" class="form-control" id="telefone2" name="telefone2" autocomplete="off"
                  :mask="['(##) #####-####']" :masked="true" v-model="cliente.telefone2" @blur.native="alterarDadosCadastro('telefone2', cliente.telefone2)
                    ">
                </TheMask>
              </div>
            </div>
            <!-- <div class="col-md-2 align-self-center">
              <label class="checkbox">
                <input type="checkbox" @click="IsWhatsApp" />
                <span class="mr-2"></span> É whatsApp?
              </label>
            </div> -->
            <div class="col-md-12 mt-1">
              <div class="form-group">
                <label v-show="isEmpresa">Nome do responsável pela empresa</label>
                <label v-show="!isEmpresa">Nome da mãe</label>
                <input type="text" class="form-control" autocomplete="off" name="nome_mae_responsavel" maxlength="100"
                  v-model="cliente.nome_mae_responsavel"
                  @blur="alterarDadosCadastro('nome_mae_responsavel', cliente.nome_mae_responsavel)" :class="{
                      'is-invalid': submitted && $v.cliente.nome_mae_responsavel.$error,
                    }" />
                <div v-if="submitted && $v.cliente.nome_mae_responsavel.$error" class="invalid-feedback">
                  Preencha o campo corretamente
                </div>
              </div>
            </div>
            <div class="col-md-12 d-none">
              <div class="separator separator-dashed my-5"></div>
            </div>
            <div class="col-md-3 mb-5 d-none">
              <label class="form-control-label">Como nos conheceu?</label>
              <div class="col-md-12 p-0">
                <select class="form-control" name="id_origem_cliente" id="id_origem_cliente"
                  v-model="contratacao.id_origem_cliente" @change="alterarDadosCadastro('id_origem_cliente', contratacao.id_origem_cliente)
                    ">
                  <!-- <option value="" disabled="" selected=""></option> -->
                  <option value="11">Carro de Som</option>
                  <option value="90">Evento</option>
                  <option value="10">Indicação</option>
                  <option value="91">Loja Móvel</option>
                  <option value="59">Outdoor</option>
                  <option value="15" selected>Outros</option>
                  <option value="12">Panfleto</option>
                  <option value="60">PAP</option>
                  <option value="96">Redes Sociais</option>
                  <option value="61">Síndico</option>
                  <option value="63">Site</option>
                  <option value="62">Zelador</option>
                </select>
                <div v-if="submitted && $v.contratacao.id_origem_cliente.$error" class="invalid-feedback"
                  style="display: block">
                  Preencha o campo corretamente
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-right p-0 pt-5">
          <button type="button" class="btn btn-primary mr-2" ref="btnAlterarTodosCampos" @click="alterarTodosCampos()">
            Continuar
          </button>
          <!-- <button type="reset" class="btn btn-secondary">Cancel</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as cep from "cep-promise";
import {  required } from "vuelidate/lib/validators";
import moment from "moment";
import { formatarDataNascimento, IsEmailValido, loadingButton, mascaraDocumento, } from "@/core/services/functionsGlobal";
import { mapActions } from "vuex";

import { ALTERAR_DADOS_CADASTRO } from "@/core/services/store/home/store/constant";
import { LOGOUT } from "@/core/services/store/auth/store/constant";
import { TheMask } from "vue-the-mask";
import { FINALIZADO } from "@/core/Enums/passoFormulario";

export default {
  name: "PassoDadosPessoais",
  data() {
    return {
      submitted: "",
      editarNome: false,
      isEmpresa: false,
    };
  },
  props: ["contratacao", "cliente", "app_hash"],
  validations: {
    cliente: {
      email1: {
        validaEmail: (email1, params) => IsEmailValido(email1, params),
      },
      telefone1: {
        required,
      },
      data_nascimento: {
        required,
        validaDataNascimento: (data_nascimento) => {
          let dataNascimento = moment(data_nascimento, "DD/MM/YYYY", true);
          if (dataNascimento.isValid()) {
            dataNascimento = moment().diff(dataNascimento, "years", false);
            if (dataNascimento < 18) return false;
            else return true;
          } else {
            return false;
          }
        },
      },
      genero: {
        required,
      },
      nome_mae_responsavel: {
        required,
      },
      razao_social: {
        required,
      },
      nome_fantasia: {
        required
      },
      id_origem_cliente: {
        required
      }
    },
    contratacao: {
      id_origem_cliente: {
        required
      },
    }
  },
  components: {
    TheMask,
  },
  created() {
    this.isEmpresa = this.cliente.isEmpresa;

    this.contratacao.id_origem_cliente = 15;
  },
  methods: {
    ...mapActions("home", [
      ALTERAR_DADOS_CADASTRO,
    ]),
    ...mapActions("auth", [LOGOUT]),
    mascaraDocumento,
    formatarDataNascimento,
    logoutApp() {
      this[LOGOUT]().then(() => {
        let url = `/inicio`;

        if(this.$route.params.slug){
          url = `/${this.$route.params.slug}`;
        }

        // this.$router.go(0);
        this.$router.push({ path: url, query: this.$route.query});
      });
    },
    alterarDadosCadastro(campo, valor, valores = null) {
      if (valores != null) {
        if (typeof this.$route.query.fbtest !== "undefined") {
          valores.fbtest = this.$route.query.fbtest;
        }
        return this[ALTERAR_DADOS_CADASTRO](valores).then((dados) => {
          return dados;
        });
      } else {
        if (valor !== null && valor !== "") {
          if (campo == "data_nascimento") {
            let dataNascimento = moment(valor, "DD/MM/YYYY", true).isValid();
            valor = dataNascimento
              ? moment(valor, "DD/MM/YYYY").format("YYYY-MM-DD")
              : "";
          } else if (campo == "email1") {
            if (!IsEmailValido(valor, true)) valor = "";
          }

          if (valor !== null && valor !== "") {
            return this[ALTERAR_DADOS_CADASTRO]({
              [campo]: valor,
              id_contratacao: this.contratacao.id_contratacao,
            }).then((dados) => {
              return dados;
            });
          }
        }
      }
    },
    async alterarTodosCampos() {
      if (!this.cliente.sem_email) {
        IsEmailValido(this.cliente.email1, true);
      }
      // seta como submitted
      this.submitted = true;

      //sinaliza para os childs que foi feito o submit do form
      this.$v.$touch();

      // valida se o campo é valido
      if (
        this.$v.cliente.nome_mae_responsavel.$invalid ||
        this.$v.cliente.razao_social.$invalid ||
        this.$v.cliente.telefone1.$invalid ||
        this.$v.contratacao.id_origem_cliente.$invalid
      ) {
        return;
      }

      if (!this.cliente.sem_email) {
        if (this.$v.cliente.email1.$invalid) return;
      }

      if (!this.isEmpresa) {
        if (
          this.$v.cliente.data_nascimento.$invalid ||
          this.$v.cliente.genero.$invalid
        ) {
          return;
        }
      }

      loadingButton(this.$refs.btnAlterarTodosCampos, true);
      await this.alterarDadosCadastro(null, null, {
        id_contratacao: this.contratacao.id_contratacao,
        telefone1: this.cliente.telefone1,
        is_whatsapp: this.numero_whatsapp,
        data_nascimento: this.cliente.data_nascimento,
        genero: this.cliente.genero,
        email1: this.cliente.email1,
        nome_mae_responsavel: this.cliente.nome_mae_responsavel,
        razao_social: this.cliente.razao_social,
        id_origem_cliente: this.contratacao.id_origem_cliente,
        passo_formulario: FINALIZADO,
      })
        .then(() => {
          this.$emit("evento-proximo-passo");
          loadingButton(this.$refs.btnAlterarTodosCampos, false);
        })
        .catch((error) => {
          loadingButton(this.$refs.btnAlterarTodosCampos, false);
          this.$vToastify.warning(
            error?.response?.data?.msg_invalido ?? "Ocorreu um erro ao salvar os dados. Aguarde um instante e tente novamente.",
            "Atenção"
          );
        });
    },
    disableInputEmail() {
      // recebe o inverso do que está
      this.cliente.email1 = "";
      this.cliente.sem_email = !this.cliente.sem_email;
      this.submitted = false;
      this.$v.$touch();
      this.$forceUpdate();
    },
    IsWhatsApp() {
      this.numero_whatsapp = this.numero_whatsapp == 0 ? 1 : 0;
      this.alterarDadosCadastro("is_whatsapp", this.numero_whatsapp);
    },
  },
};
</script>

<style>
.div-endereco {
  margin-top: 3%;
}

.div-box-space {
  margin-top: 2%;
  height: 20px;
  width: 100%;
}

select {
  -webkit-appearance: button !important;
}
</style>
