<template>
  <div>
    <div class="card card-custom mt-5">
      <div class="card-body pb-0">
        <!-- planos vigentes  -->
        <div class="row">
          <div class="col-12">
            <h5>Escolha o plano de fibra:</h5>
          </div>
          <div class="col-12">
            <div class="row mt-5">
              <div
                  class="row justify-content-center w-100 my-5"
                  v-if="loading_planos"
              >
                <div class="spinner spinner-lg spinner-primary"></div>
              </div>

              <div
                  class=""
                  v-bind:class="[
                  servicos.length > 4 ? 'col-lg-4 col-md-4 col-sm-4' : '',
                  'col-lg-3 col-md-3 col-sm-3',
                ]"
                  v-for="servico in servicos"
                  :key="servico.id_servico"
                  v-else
              >
                <label class="label-modify mouseLink position-relative">
                  <div
                      class="close-ribbon"
                      v-if="
                      typeof servico.promocoes !== 'undefined' &&
                      servico.promocoes.length > 0
                    "
                  ></div>
                  <div
                      class="card card-custom gutter-b bg-secondary text-light"
                  >
                    <div class="card-body">
                      <div
                          class="
                          row
                          justify-content-center
                          display-4
                          font-weight-bolder
                        "
                      >
                        {{ servico.nome }}
                      </div>
                      <div class="row justify-content-between mt-4">
                        <div class="align-self-center line-plano"></div>
                        <div class="">Upload | Download</div>
                        <div class="align-self-center line-plano"></div>
                      </div>
                      <div class="row justify-content-center mt-5">
                        <span
                            class="
                            label label-primary label-inline
                            font-weight-bolder
                            py-4
                            w-100
                            font-size-lg
                          "
                        >
                          INSTALAÇÃO GRÁTIS
                        </span>
                      </div>
                      <div class="mt-5 row justify-content-center">
                        <div class="h3 pt-2 pr-2 font-weight-bolder">R$</div>
                        <div class="display-3 font-weight-bolder">
                          {{
                            formataValorPrecoRender(
                                validaServicoPromocaoRender(servico),
                                servico,
                                0
                            )
                          }}
                        </div>
                        <div class="d-block pl-1">
                          <div class="pt-1 font-weight-bolder font-size-h2">
                            ,{{
                              formataValorPrecoRender(
                                  validaServicoPromocaoRender(servico),
                                  servico,
                                  1
                              )
                            }}
                          </div>
                          <div
                              class="font-weight-bolder"
                              style="margin-top: -5px"
                          >
                            /Mês
                          </div>
                        </div>
                        <div
                            class="col-12 text-center mt-2"
                            v-if="validaServicoPromocaoRender(servico)"
                        >
                          <span class="mt-2"
                          >A partir do
                            {{ servico.promocoes[0].duracao_desconto }}º mês, R$
                            {{ formatarValor(servico.valor) }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                        class="card card-custom mouse rounded-bottom-top"
                        :class="[
                        validaServicoSelecionadoRender(servico)
                          ? 'bg-primary'
                          : '',
                        '',
                      ]"
                    >
                      <div class="card-body card-modify">
                        <label
                            class="d-flex justify-content-center m-0"
                            @change="adicionarNovoServico(servico)"
                        >
                          <input
                              type="radio"
                              :checked="validaServicoSelecionadoRender(servico)"
                              name="servico"
                              class="d-none"
                              :value="servico.id"
                          />
                          <span
                              v-if="!validaServicoSelecionadoRender(servico)"
                              class="
                              text-primary
                              font-weight-boldest font-size-lg
                            "
                          >Selecionar</span
                          >
                          <span
                              v-if="validaServicoSelecionadoRender(servico)"
                              class="text-center text-white"
                          ><i class="fa fa-check text-white"></i
                          ></span>
                          <span
                              :class="[
                              validaServicoSelecionadoRender(servico)
                                ? 'bg-secondary'
                                : '',
                              '',
                            ]"
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center w-100 my-5" v-if="loading_sva">
          <div class="spinner spinner-lg spinner-primary"></div>
        </div>
        <div class="row" v-else-if="this.servicos_carrinho.length > 0">
          <div class="col-12 text-start">
            <h6>Benefícios inclusos no plano:</h6>
          </div>
          <div class="col-12">
            <div class="d-flex">
              <div
                  :class="[
                  validaServicoSelecionadoValorAgregado(servico_valor_agregado)
                    ? ''
                    : 'd-none',
                  '',
                ]"
                  class="symbol symbol-circle symbol-60 mr-6 text-center"
                  v-for="servico_valor_agregado in servicos_valores_agregados"
                  v-bind:key="servico_valor_agregado.id"
              >
                <img
                    alt="Pic"
                    :src="servico_valor_agregado.imagem"
                    :class="[
                    validaServicoSelecionadoValorAgregado(
                      servico_valor_agregado
                    )
                      ? ''
                      : 'opacity-70',
                    '',
                  ]"
                    v-b-tooltip.hover
                    :title="servico_valor_agregado.descricao"
                />
                <small
                    :class="[
                    validaServicoSelecionadoValorAgregado(
                      servico_valor_agregado
                    )
                      ? 'd-none'
                      : '',
                    '',
                  ]"
                >Não incluso</small
                >
                <small
                    :class="[
                    validaServicoSelecionadoValorAgregado(
                      servico_valor_agregado
                    )
                      ? 'd-none'
                      : 'd-none',
                    '',
                  ]"
                >Incluso</small
                >
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="pacotes_servicos.length > 0">
          <div class="col-12">
            <div class="separator separator-dashed my-5"></div>
          </div>
          <div class="col-12 text-start mb-5">
            <h5>Pacotes adicionais:</h5>
          </div>
          <div
              class="col-lg-3 col-md-3 col-sm-3"
              v-for="pacote_servico in pacotes_servicos"
              v-bind:key="pacote_servico.id_pacote_servico_cidade"
          >
            <div class="card card-custom gutter-b bg-secondary text-light mb-0 rounded-top-bottom">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 text-center">
                    <div class="font-size-h3 font-weight-bolder">
                      {{ pacote_servico.pacote.descricao }}
                    </div>
                    <div class="separator separator-solid my-2"></div>
                    <div class="mt-5 row justify-content-center">
                      <div class="h3 pt-2 pr-2 font-weight-bolder">R$</div>
                      <div class="display-3 font-weight-bolder">
                        {{
                          Number(pacote_servico.pacote.valor)
                              .toFixed(2)
                              .toString()
                              .split(".")[0]
                        }}
                      </div>
                      <div class="d-block pl-1">
                        <div class="pt-1 font-weight-bolder font-size-h2">
                          ,{{
                            Number(pacote_servico.pacote.valor)
                                .toFixed(2)
                                .toString()
                                .split(".")[1]
                          }}
                        </div>
                        <div
                            class="font-weight-bolder"
                            style="margin-top: -5px"
                        >
                          /Mês
                        </div>
                      </div>
                    </div>

                    <div
                        class="
                        d-flex
                        justify-content-center
                        mt-3
                        align-items-center
                      "
                    >
                      <button
                          type="button"
                          class="btn btn-sm btn-light-danger btn-icon"
                          @click="
                          atualizarServicoAdicional(pacote_servico, 'remover')
                        "
                      >
                        <i class="ki ki-minus icon-xs"></i>
                      </button>
                      <div class="font-size-h6 font-weight-bolder mx-5">
                        {{
                          validaQuantidadeServicoAdicionalRender(pacote_servico)
                              .quantidade
                        }}
                      </div>
                      <button
                          type="button"
                          class="btn btn-sm btn-light-success btn-icon"
                          @click="
                          atualizarServicoAdicional(pacote_servico, 'adicionar')
                        "
                      >
                        <i class="ki ki-plus icon-xs"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-custom mouse bg-primary mouseLink rounded-bottom-top" @click="abrirFecharModalServicosAdicionais()">
              <div class="card-body card-modify text-center text-white fw-bold">
                Saiba mais
              </div>
            </div>

          </div>
        </div>

        <div class="separator separator-dashed my-5"></div>
        <div class="row justify-content-start mt-5">
          <div class="col-12">
            <h5>Escolha qual é o melhor dia para pagamento:</h5>
          </div>
        </div>
        <div
            class="row justify-content-center w-100 my-5"
            v-if="loading_dias_pagamento"
        >
          <div class="spinner spinner-lg spinner-primary"></div>
        </div>
        <div class="row justify-content-start" v-else>
          <div
              class="col-lg-3 col-md-3 col-sm-3"
              v-for="dia_pagamento in dias_pagamento_disponiveis"
              v-bind:key="dia_pagamento"
          >
            <label
                class="option"
                :class="[
                validaDiaPagamentoSelecionadoRender(dia_pagamento)
                  ? 'bg-secondary'
                  : '',
                '',
              ]"
            >
              <span class="option-control">
                <span
                    class="radio"
                    @change="alterarDadosCadastro('dia_pagamento', dia_pagamento)"
                >
                  <input
                      type="radio"
                      name="dia_pagamento"
                      :value="dia_pagamento"
                      :checked="
                      validaDiaPagamentoSelecionadoRender(dia_pagamento)
                    "
                  />
                  <span></span>
                </span>
              </span>
              <span class="option-label">
                <span class="option-head">
                  <span
                      class="option-title font-weight-bold h6"
                      :class="[
                      validaDiaPagamentoSelecionadoRender(dia_pagamento)
                        ? 'text-light'
                        : 'text-dark-75 text-hover-primary',
                      '',
                    ]"
                  >Dia {{ dia_pagamento }}</span
                  >
                  <div class="d-flex flex-column text-right"></div>
                </span>
              </span>
            </label>
          </div>
        </div>
      </div>

      <div class="separator separator-dashed my-5"></div>
      <div class="row pl-5 pr-5 justify-content-end">
        <div class="card-custom">
          <div class="card-body pt-5">
            <h6>
              Valor Total: R$
              {{
                formatarValor(valor_carrinho.total - valor_carrinho.desconto)
              }}
              / Mês
            </h6>
            <span v-if="validaServicoPromocaoCarrinhoRender()">
              A partir do
              {{
                validaServicoPromocaoCarrinhoRender().promocoes[0]
                    .duracao_desconto
              }}º mês, R$
              {{ formatarValor(valor_carrinho.total) }}
            </span>
          </div>
        </div>
      </div>
      <div
          class="card-footer text-right pt-5 pb-5"
          v-if="!loading_planos && !loading_dias_pagamento && !loading_sva"
      >
        <button
            type="button"
            class="btn btn-primary"
            ref="btnValidaServicos"
            @click="validaServicos()"
        >
          Continuar
        </button>
      </div>
    </div>
    <b-modal
        id="modal_servico_adicional_alerta"
        ref="modal_servico_adicional_alerta"
        size="md"
        centered
        hide-footer
        :title="dados_modal_servico_adicional.titulo_recomendacao"
    >
      <div v-html="dados_modal_servico_adicional.descricao_recomendacao"></div>
      <div
          class="d-flex justify-content-end gap mt-3"
          v-if="dados_modal_servico_adicional.botao_confirmacao"
      >
        <button
            class="btn btn-light"
            @click="abrirFecharModalServicosAdicionais()"
        >
          Não, quero esperar
        </button>
        <button
            class="btn btn-primary"
            @click="adicionarServicoAdicionalPorRecomendacao()"
        >
          Sim, desejo
        </button>
      </div>
      <div v-else class="d-flex justify-content-end gap mt-3">
        <button
            class="btn btn-primary"
            @click="abrirFecharModalServicosAdicionais()"
        >
          Ok
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api/api.service";
import {
  formatarMoedaSemCifrao,
  loadingButton,
} from "@/core/services/functionsGlobal/index";
import {PAGAMENTO, FINALIZADO} from "@/core/Enums/passoFormulario";
// import ModalAlertaServicoAdicional from "@/views/home/components/ModalAlertaServicoAdicional.vue";

export default {
  name: "PassoEscolhaPlano",
  props: ["contratacao", "cliente"],
  components: {
    // ModalAlertaServicoAdicional
  },
  data() {
    return {
      endereco: {},
      servicos: [],
      dias_pagamento_disponiveis: [],
      servicos_valores_agregados: [],
      loading_planos: true,
      loading_dias_pagamento: true,
      loading_sva: true,
      servicos_carrinho: [],
      isDisableBtns: false,
      escolhaTipoContratacao: null,
      redirecionar:localStorage.getItem("redirecionar"),
      valor_carrinho: {
        total: 0.0,
        desconto: 0.0,
      },
      isEmpresa: false,
      tipoPlano: 0, // 0 é residencial e 1 é planos empresarial
      passo_formulario: PAGAMENTO,
      pacotes_servicos: [],
      dados_modal_servico_adicional: {
        descricao_recomendacao:
            "De acordo com sua localização e o tamanho de seu imóvel, notamos que seja necessário um produto para melhor seu WIFI, deseja adicionar o mesmo?",
        titulo_recomendacao:"Indicação",
        botao_confirmacao: true, // se é só um botao de ok ou de confirmacao de aceite
      },
    };
  },
  async created() {
    this.endereco = this.contratacao.endereco;
    this.servicos_carrinho = this.contratacao.contratacao_servicos;
    await this.buscarServicos();
    await this.buscarDatasPagamento();
    await this.buscarTodosSva();

    if (this.pacotes_servicos.length > 0) {
      // descricao_recomendacao
      let pacoteServico = this.pacotes_servicos[0];

      if (pacoteServico.exibe_modal_checkout && this.redirecionar == 1) {
        this.dados_modal_servico_adicional.descricao_recomendacao = pacoteServico.descricao_recomendacao;
        this.dados_modal_servico_adicional.titulo_recomendacao = pacoteServico.titulo_recomendacao;
        this.dados_modal_servico_adicional.botao_confirmacao = false;
        this.abrirFecharModalServicosAdicionais();
      }
    }
  },
  methods: {
    formatarValor(valor) {
      return formatarMoedaSemCifrao(valor);
    },
    async buscarServicos() {
      const {data} = await ApiService.query(
          `contratacao/servico/disponiveis/${this.endereco.id_cidade}`,
          {
            id_bairro: this.contratacao.endereco.id_bairro,
            id_imovel_tamanho: this.contratacao.endereco.id_imovel_tamanho,
          }
      );

      this.loading_planos = false;
      this.servicos = data;
      this.filtrarPacotesPorServicosCarrinho();

      this.verificaPlanoEscolhidoSite();
      this.calculaValorTotalServicos();
    },
    async buscarDatasPagamento() {
      const {data} = await ApiService.get("parametro?key=dias_pagamento");
      this.dias_pagamento_disponiveis = data;
      this.loading_dias_pagamento = false;
    },
    async buscarTodosSva() {
      const {data} = await ApiService.get(
          "contratacao/servico/valores_agregados"
      );
      this.servicos_valores_agregados = data;
      this.loading_sva = false;
    },
    async adicionarNovoServico(servico) {
      // pego item que será removido
      this.servicos_carrinho = this.servicos_carrinho.filter((servicoItem) => {
        if (servicoItem.id_servico_tipo != 1) return servicoItem;
      });

      // realiza a inserção do novo item
      await ApiService.post("contratacao/servico/adicionar", {
        id_contratacao: this.contratacao.id_contratacao,
        id_servico: servico.id_servico,
      })
          .then(({data}) => {
            this.servicos_carrinho = data.contratacao_servico;
            this.calculaValorTotalServicos();
            this.filtrarPacotesPorServicosCarrinho();

            if (this.pacotes_servicos.length > 0) {
              this.dados_modal_servico_adicional = {
                descricao_recomendacao:this.pacotes_servicos[0].descricao_recomendacao,
                titulo_recomendacao:this.pacotes_servicos[0].titulo_recomendacao,
                botao_confirmacao: true, // se é só um botao de ok ou de confirmacao de aceite
              };

              if (this.pacotes_servicos[0].exibe_modal_checkout && this.redirecionar == 1)
                this.abrirFecharModalServicosAdicionais();
            }
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              this.$vToastify.warning(error.response.data.message, "Atenção");
            } else {
              this.$vToastify.warning("Ocorreu um erro", "Atenção");
            }
          });
    },
    async removerAntigoServico(id_contratacao_servico) {
      await ApiService.post("contratacao/servico/remover", {
        id_contratacao: this.contratacao.id_contratacao,
        id_contratacao_servico: id_contratacao_servico,
      });
    },
    async atualizarServicoAdicional(pacote_servico, acao, quantidade = null) {
      let contratacaoServico = this.servicos_carrinho[0];
      // this.contratacao.contratacao_servicos.find(
      // (contratacaoServico) =>
      // contratacaoServico.id_servico == this.servicos_carrinho[0].id_servico
      // );

      ApiService.post("contratacao/servico-adicionais/atualizar", {
        id_contratacao: this.contratacao.id_contratacao,
        id_contratacao_servico: contratacaoServico.id_contratacao_servico,
        id_pacote: pacote_servico.id_pacote,
        acao: acao,
        quantidade: quantidade,
      })
          .then(({data}) => {
            this.servicos_carrinho[0].contratacao_servicos_adicionais =
                this.servicos_carrinho[0].contratacao_servicos_adicionais.filter(
                    (servicoAdicional) => {
                      return servicoAdicional.id_pacote != pacote_servico.id_pacote;
                    }
                );

            if (data?.contratacao_servico_adicional)
              this.servicos_carrinho[0].contratacao_servicos_adicionais.push(
                  data.contratacao_servico_adicional
              );

            this.calculaValorTotalServicos();
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              this.$vToastify.warning(error.response.data.message, "Atenção");
            } else {
              this.$vToastify.warning("Ocorreu um erro", "Atenção");
            }
          });
    },
    adicionarServicoAdicionalPorRecomendacao() {
      this.atualizarServicoAdicional(
          this.pacotes_servicos[0],
          "adicionar",
          this.pacotes_servicos[0].quantidade_maxima_contratacao
      ).then(() => {
        this.abrirFecharModalServicosAdicionais();
      });
    },
    validaServicoSelecionadoRender(servicoIteracao) {
      // retorna se o item que ta sendo interado, é selecionado
      return this.servicos_carrinho.some(
          (servico) => servico.id_servico == servicoIteracao.id_servico
      );
    },
    validaDiaPagamentoSelecionadoRender(dia_pagamento_interacao) {
      return (
          Number(dia_pagamento_interacao) ===
          Number(this.contratacao.dia_pagamento)
      );
    },

    validaQuantidadeServicoAdicionalRender(pacote_servico) {
      let pacote =
          this.servicos_carrinho[0]?.contratacao_servicos_adicionais?.find(
              (servicoContratacaoAdicional) => {
                return (
                    servicoContratacaoAdicional.id_pacote == pacote_servico.id_pacote
                );
              }
          );

      if (!pacote) return {quantidade: 0};
      return pacote;
    },
    validaServicoSelecionadoValorAgregado(servico_valor_agregado_interacao) {
      return this.servicos_carrinho.some((servicoContratacao) => {
        return servicoContratacao.servico.valores_agregados.some(
            (valores_agregados) =>
                valores_agregados.id_servico_valor_agregado ==
                servico_valor_agregado_interacao.id_servico_valor_agregado
        );
        // return servicoContratacao.servico.valores_agregados.some(
        //   (valores_agregados) =>
        //     valores_agregados.id_servico_valor_agregado ==
        //     servico_valor_agregado_interacao.id_servico_valor_agregado
        // );
      });
    },
    validaServicoPromocaoRender(servico) {
      return servico?.promocoes?.length > 0;
    },
    validaServicoPromocaoCarrinhoRender() {
      let servico = this.servicos.find(
          (servico) => servico.id_servico == this.servicos_carrinho[0]?.id_servico && servico.promocoes.length > 0
      );

      return servico;
    },
    formataValorPrecoRender(retornaValorPromocional, servico, posicao) {
      if (retornaValorPromocional) {
        return (
            Number(servico.valor) - Number(servico.promocoes[0].valor_desconto)
        )
            .toFixed(2)
            .toString()
            .split(".")[posicao];
      } else {
        return Number(servico.valor).toFixed(2).toString().split(".")[posicao];
      }
    },
    verificaPlanoEscolhidoSite() {
      let servico = this.servicos.find(
          (servico) => servico.nome_slug == localStorage.getItem("plano")
      );
      if (typeof servico !== "undefined") {
        localStorage.removeItem("plano");
        this.adicionarNovoServico(servico);
      }
    },
    calculaValorTotalServicos() {
      this.valor_carrinho = {total: 0.0, desconto: 0.0};
      this.servicos_carrinho.forEach((servicoContratacao) => {
        let servico = this.servicos.find(
            (servico) => servicoContratacao.id_servico == servico.id_servico
        );

        this.valor_carrinho.total += Number(servico.valor);
        if (servico?.promocoes?.length > 0) {
          this.valor_carrinho.desconto += Number(
              servico.promocoes[0].valor_desconto
          );
        }

        if (servicoContratacao.contratacao_servicos_adicionais) {
          servicoContratacao.contratacao_servicos_adicionais.forEach(
              (servicoAdicional) => {
                this.valor_carrinho.total += Number(
                    servicoAdicional.pacote.valor
                );
              }
          );
        }
      });
    },
    filtrarPacotesPorServicosCarrinho() {
      this.servicos_carrinho.forEach((servicoContratacao) => {
        // pega os servicos do carrinho, verifica os pacotes existentes
        let servicos = this.servicos.filter(
            (servico) => servico.id_servico == servicoContratacao.id_servico
        );

        servicos.forEach((servico) => {
          if (servico.pacotes.length > 0 && servico.pacotes[0].exibe_checkout && this.redirecionar == 1)
            this.pacotes_servicos = [...servico.pacotes];
        });
      });
    },
    alterarDadosCadastro(campo, valor, valores = null) {
      if (valores != null) {
        return ApiService.post("contratacao/alterar", valores).then((dados) => {
          return dados;
        });
      } else {
        if (valor != null && valor != "") {
          if (campo == "dia_pagamento") this.contratacao.dia_pagamento = valor;

          return ApiService.post("contratacao/alterar", {
            [campo]: valor,
            id_contratacao: this.contratacao.id_contratacao,
          }).then((dados) => {
            return dados;
          });
        }
      }
    },
    async validaServicos() {
      if (this.servicos_carrinho.length > 0) {
        loadingButton(this.$refs.btnValidaServicos, true);
        this.passo_formulario = FINALIZADO;

        await this.alterarDadosCadastro(null, null, {
          id_contratacao: this.contratacao.id_contratacao,
          passo_formulario: this.passo_formulario,
        })
            .then(() => {
              this.$emit("evento-proximo-passo");
              loadingButton(this.$refs.btnValidaServicos, false);
            })
            .catch(() => {
              loadingButton(this.$refs.btnValidaServicos, false);
              this.$vToastify.warning(
                  "Ocorreu um erro ao salvar os dados. Aguarde um instante e tente novamente.",
                  "Atenção"
              );
            });
      } else {
        this.$vToastify.warning(
            "É necessário escolher um plano para continuar",
            "Atenção"
        );
      }
    },
    alterarTiposPlanos() {
      // 0 é residencial e 1 é planos empresarial
      if (this.tipoPlano == 0) {
        // 0 é empresarial
        this.tipoPlano = 1;
        this.alterarTipoContratacao(1);
      } else {
        // 1 é residencial
        this.tipoPlano = 0;
        this.alterarTipoContratacao(1);
      }
    },
    abrirFecharModalServicosAdicionais() {
      this.$refs["modal_servico_adicional_alerta"].toggle();
      document.body.classList.remove("modal-open");
    },
  },
};
</script>

<style>
.mouseLink {
  cursor: pointer;
}

.mouseLink:hover {
  opacity: 0.8;
  background: #fafafa;
}

.card-modify {
  padding: 1rem !important;
}

.label-modify {
  display: block;
  margin-bottom: 0;
}

.close-ribbon {
  position: absolute;
  height: 1.9rem;
  right: 1rem;
  z-index: 1;
  color: #eee;
  font-size: 0.5rem;
  width: 1.5em;
  content: "";
  border: 1.5em solid #008080;
  border-bottom-color: transparent;
}

.gap {
  gap: 0.5rem;
}

.rounded-bottom-top {
  border-radius: 0 0 0.42rem 0.42rem;
}
.rounded-top-bottom {
  border-radius: 0.42rem 0.42rem 0 0;
}

.modal-open{
  overflow:visible !important;
}
</style>
