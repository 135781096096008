<template>
  <div>
    <div class="card card-custom gutter-b descricao-card">
      <div class="
          card-body
          d-flex
          align-items-center
          justify-content-between
          flex-wrap
        ">
        <div class="mr-2">
          <h5 class="font-weight-bolder">
            Olá {{ cliente.razao_social }}, você está contratando com o
            documento
            {{ mascaraDocumento(cliente.cpf_cnpj) }}
          </h5>
          <div class="text-dark-50 font-size-lg mt-3">
            <span>
              Quer contratar com outro documento?
              <b class="text-danger">
                <button @click="logoutApp()" class="btn-link text-danger font-weight-bolder">
                  Sair
                </button>
              </b>
            </span>
            <br />
            <div class="mt-2"></div>
            <span class="text-dark-50 font-size-lg">
              Precisa atualizar o nome?
              <b class="text-dark">
                <button class="btn-link font-weight-bolder" @click="() => (editarNome = true)">
                  Alterar
                </button>
              </b>
            </span>
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-1" v-show="editarNome">
        <div class="form-group">
          <label>Nome</label>
          <div class="input-group">
            <input type="text" class="form-control" autocomplete="off" maxlength="100" v-model="cliente.razao_social"
              :class="{
                'is-invalid': submitted && $v.cliente.razao_social.$error,
              }" />
            <div class="input-group-append">
              <button class="btn btn-primary" type="button" @click="() => (editarNome = false)">
                Salvar
              </button>
            </div>
          </div>
          <div v-if="submitted && $v.cliente.razao_social.$error" class="invalid-feedback">
            Preencha o campo corretamente
          </div>
        </div>
      </div>
    </div>
    <div class="card card-custom">
      <h6 class="text-center pt-5 pl-2 pr-2" v-if="evento.id_pessoa_criacao || !evento?.id_evento">
        Informe o CEP do endereço do evento
      </h6>

      <div class="kt-form__section kt-form__section--first card-body">
        <div class="row" v-if="evento.id_pessoa_criacao || !evento?.id_evento">
          <div class="col-lg-4 offset-lg-4 mb-5">
            <div class="input-group">
              <TheMask id="cep" :mask="['#####-###']" name="cep" autocomplete="off" v-model="endereco.cep"
                @blur.native="buscaCep" :class="{
                  'is-invalid': submitted && $v.endereco.cep.$error,
                }" class="form-control"></TheMask>

              <div class="input-group-append">
                <button class="btn btn-primary" ref="btnBuscarCep" type="button" @click="buscaCep">
                  Buscar endereço
                </button>
              </div>
              <div v-if="submitted && $v.endereco.cep.$error" class="invalid-feedback">
                Preencha o campo corretamente
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex flex-wrap justify-content-center" v-if="buscouCep && indisponivel">
          <p class="lead w-100">
            Eu ainda não tenho disponibilidade para sua cidade, mas, estou em
            constante expansão, então me acompanhe nas redes sociais para
            ficar por dentro das novidades.
          </p>
          <a href="https://facebook.com/nicnetfibraoptica/" target="_blank" class="btn btn-facebook mr-2"><i
              class="socicon-facebook"></i> Facebook</a>
          <a href="https://instagram.com/nicnetfibraoptica/" target="_blank" class="btn btn-instagram"><i
              class="socicon-instagram"></i> Instagram</a>
        </div>

        <div class="div-endereco-evento" v-else-if="this.buscouCep">
          <div class="row div-endereco">
            <div class="col-12 mb-4 text-center atendimento_cidade">
              <div v-if="evento.id_evento && evento.id_pessoa_criacao">
                <h3 class="mb-0">
                  {{ endereco.cidade.nome }} / {{ endereco.cidade.estado.sigla }}
                </h3>
              </div>

              <div class="d-flex flex-column" v-if="evento.id_evento && !evento.id_pessoa_criacao">
                <h1 class="mb-4">{{ evento.nome }}</h1>
                <span>{{ endereco.logradouro }},
                  {{ endereco.numero }},
                  {{ endereco.bairro_string }}
                </span>
                <span>{{ endereco.cidade?.nome?.toUpperCase() }}/{{ endereco.cidade.estado.sigla }} - {{
                  mascaraCep(endereco.cep) }}</span>
              </div>
            </div>

            <div class="div-box-space"></div>

            <div class="col-12" v-if="!evento.id_evento || evento.id_pessoa_criacao">
              <div class="row">
                <div class="col-12 col-md-8">
                  <div class="form-group">
                    <label>Endereço</label>
                    <input type="text" class="form-control" id="endereco" autocomplete="off" name="endereco"
                      v-model="endereco.logradouro" :disabled="!this.permiteAlterarEndereco" :class="{
                        'is-invalid': submitted && $v.endereco.logradouro.$error,
                      }" />
                    <div v-if="submitted && $v.endereco.logradouro.$error" class="invalid-feedback">
                      Preencha o campo corretamente
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <div class="form-group">
                      <label>Número</label>
                      <input type="tel" class="form-control" id="numero" autocomplete="off" name="numero"
                        v-model="endereco.numero" :class="{
                          'is-invalid': submitted && $v.endereco.numero.$error,
                        }" />
                      <div v-if="submitted && $v.endereco.numero.$error" class="invalid-feedback">
                        Preencha o campo corretamente
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Bairro</label>
                    <input type="text" class="form-control" autocomplete="off" name="bairro_string" maxlength="30"
                      v-model="endereco.bairro_string" :class="{
                        'is-invalid': submitted && $v.endereco.bairro_string.$error,
                      }" />
                    <div v-if="submitted && $v.endereco.bairro_string.$error" class="invalid-feedback">
                      Preencha o campo corretamente
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Complemento</label>
                    <input type="text" class="form-control" id="complemento" autocomplete="off" name="complemento"
                      maxlength="30" v-model="endereco.complemento" />
                  </div>
                </div>

                <div class="col-12 my-6 separator separator-dashed"></div>

                <div class="col-12 my-3">
                  <h6 class="font-weight-bolder">Sobre o evento</h6>
                </div>

                <div class="col-12 col-lg-8">
                  <div class="form-group">
                    <label>Nome do evento</label>
                    <input type="text" class="form-control" id="nome" v-model="evento.nome" autocomplete="off" name="nome"
                      :class="{
                        'is-invalid': submitted && $v.evento.nome.$error,
                      }" />
                    <div v-if="submitted && $v.evento.nome.$error" class="invalid-feedback">
                      Preencha o campo corretamente
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6 col-lg-2">
                  <div class="form-group">
                    <label>Data Início</label>
                    <TheMask type="text" class="form-control" id="data_inicio" name="data_inicio" :masked="true"
                      autocomplete="off" :mask="['##/##/####']" v-model="evento.data_inicio" :class="{
                        'is-invalid': submitted && $v.evento.data_inicio.$error,
                      }"></TheMask>
                    <div v-if="submitted && $v.evento.data_inicio.$error" class="invalid-feedback">
                      Preencha o campo corretamente
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6 col-lg-2">
                  <div class="form-group">
                    <label>Data Fim</label>
                    <TheMask type="text" class="form-control" id="data_fim" name="data_fim" :masked="true"
                      autocomplete="off" :mask="['##/##/####']" v-model="evento.data_fim" :class="{
                        'is-invalid': submitted && $v.evento.data_fim.$error,
                      }"></TheMask>
                    <div v-if="submitted && $v.evento.data_fim.$error" class="invalid-feedback">
                      Preencha o campo corretamente
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <label>Nome Técnico responsável (Opcional)</label>
                <input type="text" class="form-control" id="nome_tecnico_responsavel" autocomplete="off" name="nome_tecnico_responsavel"
                  v-model="evento_contratacao.nome_tecnico_responsavel" />
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <label>Telefone Técnico responsável (Opcional)</label>
                <TheMask :mask="['(##) #####-####']" name="telefone_tecnico_responsavel"
                 autocomplete="off"
                    v-model="evento_contratacao.telefone_tecnico_responsavel" class="
                        form-control
                      "></TheMask>
              </div>
            </div>

            <div class="col-12" v-if="evento.id_evento && !evento.id_pessoa_criacao">
              <div class="form-group">
                <label>Observações</label>
                <textarea type="text" class="form-control" id="observacoes" autocomplete="off" name="observacoes"
                  v-model="evento_contratacao.observacoes"></textarea>
              </div>
            </div>

            <div class="col-12 my-6 separator separator-dashed"></div>
            <div class="col-12 my-3">
              <h6 class="font-weight-bolder">Serviços</h6>
            </div>
            <table class="table g-5 mb-0 fw-bolder text-gray-700" id="table-movimentacao-estoque">
              <!-- <thead>
                <tr class="border-bottom fs-7 fw-bolder text-gray-700 text-uppercase">
                  <th class=""></th>
                  <th></th>
                </tr>
              </thead> -->
              <tbody>
                <tr class="border-bottom border-bottom-dashed">
                  <td class="align-middle">
                    Quantidade dispositivos conectados via Cabo
                  </td>
                  <td class="text-right align-middle">
                    <div class="d-flex justify-content-end">
                      <input type="number" @input="validarNumero($event,'quantidade_dispositivos_cabo')" class="form-control w-max-25"
                        id="quantidade_dispositivos_cabo" autocomplete="off" name="quantidade_dispositivos_cabo"
                        v-model="evento_contratacao.quantidade_dispositivos_cabo" :class="{
                          'is-invalid': submitted && $v.evento_contratacao.quantidade_dispositivos_cabo.$error,
                        }" />
                    </div>
                  </td>
                </tr>

                <tr class="border-bottom border-bottom-dashed">
                  <td class="align-middle">
                    Quantidade dispositivos conectados via WIFI
                  </td>
                  <td class="text-right align-middle">
                    <div class="d-flex justify-content-end">
                      <input type="number" @input="validarNumero($event,'quantidade_dispositivos_wifi')" class="form-control w-max-25"
                        id="quantidade_dispositivos_wifi" autocomplete="off" name="quantidade_dispositivos_wifi"
                        v-model="evento_contratacao.quantidade_dispositivos_wifi" :class="{
                          'is-invalid': submitted && $v.evento_contratacao.quantidade_dispositivos_wifi.$error,
                        }" />
                    </div>
                  </td>
                </tr>

                <tr class="border-bottom border-bottom-dashed">
                  <td class="align-middle">
                    Quantidade de IP Público
                  </td>
                  <td class="text-right align-middle">
                    <div class="d-flex justify-content-end w-100">
                      <div class="d-flex align-items-center gap-7 justify-content-between w-max-25">
                        <button type="button" @click="alterarQuantidadeIpPublico('remove')"
                          class="btn btn-sm btn-light-danger btn-icon">
                          <i class="ki ki-minus icon-xs"></i>
                        </button>
                        <div class="font-size-h6 font-weight-bolder mx-5">
                          {{ evento_contratacao.quantidade_ip_publico }}
                        </div>
                        <button type="button" @click="alterarQuantidadeIpPublico('add')"
                          class="btn btn-sm btn-light-success btn-icon">
                          <i class="ki ki-plus icon-xs"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>

        <div class="card-footer text-right p-0 pt-5 mt-10" v-if="!indisponivel">
          <button type="button" class="btn btn-primary mr-2" ref="btnAlterarTodosCampos" @click="salvar()">
            Continuar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as cep from "cep-promise";
import { required, maxLength } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api/api.service";
import moment from "moment";
import {
  // retirarMascaraCep,
  loadingButton,
  verificaDisponibilidade,
  validaCep,
  formatarDataNascimento,
  mascaraCep
} from "@/core/services/functionsGlobal";
import { mapActions } from "vuex";
import {
  mensagemCumprimento,
  mascaraDocumento,
  retiraMascaraTelefone,
} from "@/core/services/functionsGlobal";

import {
  BUSCAR_CIDADES_DISPONIVEIS,
  BUSCAR_BAIRROS_DISPONIVEIS,
  ALTERAR_DADOS_CADASTRO,
} from "@/core/services/store/home/store/constant";
import { LOGOUT } from "@/core/services/store/auth/store/constant";
import { TheMask } from "vue-the-mask";
import { ENDERECO } from "@/core/Enums/passoFormulario";
export default {
  name: "PassoEndereco",
  data() {
    return {
      endereco: {
        id_endereco: null,
        cep: null,
        logradouro: null,
        numero: null,
        complemento: null,
        id_cidade: null,
        id_bairro: null,
        bairro_string: null,
        condominio: null,
        bloco: null,
        apartamento: null,
        id_condominio: null,
        id_imovel_tamanho: null,
        id_imovel_tipo: null,
        cidade: {
          nome: null,
          id_cidade: null,
          estado: {
            nome: null,
            sigla: null
          }
        }
      },
      evento: {
        nome: null,
        data_inicio: null,
        data_fim: null,
        slug: null,
        id_pessoa_criacao: null,
        endereco: {}
      },
      evento_contratacao: {
        id_evento_contratacao: null,
        quantidade_dispositivos_cabo: 0,
        quantidade_dispositivos_wifi: 0,
        quantidade_ip_publico: 0,
        valor: null,
        observacoes: null,
      },
      tamanhosImoveis: [],
      tiposImoveis: [],
      submitted: "",
      indisponivel: true, //comeca como true
      buscouCep: false,
      permiteAlterarEndereco: false,
      listaBairros: [],
      editarNome: false,
      isEmpresa: false,
      origemERP: 0,
      updated: 0,
    };
  },
  props: ["contratacao", "cliente", "app_hash"],
  validations: {
    endereco: {
      cep: {
        required,
        validaCep: (cep) => validaCep(cep),
      },
      logradouro: {
        required,
      },
      numero: {
        required,
      },
      bairro_string: {
        required,
      },
    },
    evento: {
      nome: {
        required,
      },
      data_inicio: {
        required,
        validaDataEvento: (data_inicio) => {
          data_inicio = moment(data_inicio, "DD/MM/YYYY", true);
          return data_inicio.isValid() && data_inicio >= moment();
        },
      },
      data_fim: {
        required,
        validaDataEvento: (data_fim) => {
          data_fim = moment(data_fim, "DD/MM/YYYY", true);
          return data_fim.isValid() && data_fim >= moment();
        },
      },
    },
    cliente: {
      rg_inscricao: {
        required,
        maxLength: maxLength(15),
      },
      razao_social: {
        required,
      },
    },
    evento_contratacao: {
      quantidade_dispositivos_cabo: {
        required,
      },
      quantidade_dispositivos_wifi: {
        required,
      },
    },
  },
  components: {
    TheMask,
  },
  async created() {

    this.isEmpresa = this.cliente.isEmpresa;

    if (this.contratacao.id_contratacao) {
      this.endereco = this.contratacao.eventos[0]?.endereco;
      this.evento = this.contratacao.eventos[0];
      this.evento_contratacao = this.contratacao.eventos[0].pivot;

      this.evento_contratacao.quantidade_ip_publico = this.evento_contratacao.quantidade_ip_publico++;
      this.evento_contratacao.quantidade_dispositivos_cabo = this.evento_contratacao.quantidade_dispositivos_cabo++;
      this.evento_contratacao.quantidade_dispositivos_wifi = this.evento_contratacao.quantidade_dispositivos_wifi++;
    }

    if (!this.contratacao.id_contratacao && this.$route.params.slug) {
      this.evento = await ApiService.get(`eventos/buscar-por-slug?slug=${this.$route.params.slug}`).then(({ data }) => data);
      this.evento.data_inicio = formatarDataNascimento(false, this.evento.data_inicio);
      this.evento.data_fim = formatarDataNascimento(false, this.evento.data_fim);
      this.endereco = this.evento.endereco;
    }



    if (this.endereco.cep != null && this.endereco.cep != "") {
      this.buscouCep = true;

      if (this.evento_contratacao) {
        this.indisponivel = false;
      }

      if (this.endereco.cep.substring(this.endereco.cep.length - 3) == "000") {
        this.permiteAlterarEndereco = true;
      } else {
        this.permiteAlterarEndereco = false;
      }
    }

  },
  methods: {
    ...mapActions("home", [
      BUSCAR_CIDADES_DISPONIVEIS,
      BUSCAR_BAIRROS_DISPONIVEIS,
      ALTERAR_DADOS_CADASTRO,
    ]),
    ...mapActions("auth", [LOGOUT]),
    mensagemCumprimento,
    mascaraDocumento,
    retiraMascaraTelefone,
    formatarDataNascimento,
    mascaraCep,
    logoutApp() {
      this[LOGOUT]().then(() => {
        let url = `/inicio`;

        if (this.$route.params.slug) {
          url = `/${this.$route.params.slug}`;
        }

        // this.$router.go(0);
        this.$router.push({ path: url, query: this.$route.query });
      });
    },
    async buscaCep() {
      // seta como submitted
      this.submitted = true;

      //sinaliza para os childs que foi feito o submit do form
      this.$v.endereco.cep.$touch();

      // valida se o campo é valido
      if (this.$v.endereco.cep.$invalid) {
        return;
      }

      // adiciona o loading no button
      loadingButton(this.$refs.btnBuscarCep, true);
      ApiService.get(`endereco/consultaCep?cep=${this.endereco.cep}`)
        .then(async ({ data: cidadeCep }) => {
          // verifica a disponibilidade no cep informado
          let cidadeHub = verificaDisponibilidade(
            cidadeCep.city,
            await this.buscarCidades()
          );

          if (!cidadeHub?.cidade) {
            this.indisponivel = true;
            this.buscouCep = true;
            localStorage.setItem("indisponivel", this.indisponivel);
            loadingButton(this.$refs.btnBuscarCep, false);
            return;
          }

          this.indisponivel = false;
          this.buscouCep = true;
          this.permiteAlterarEndereco = true;

          if (cidadeCep.street != "") {
            this.permiteAlterarEndereco = false;
          }

          this.endereco = {
            cep: cidadeCep.zipCode,
            id_cidade: cidadeHub.cidade.id_cidade,
            logradouro: cidadeCep.street,
            bairro_string: cidadeCep.district,
            cidade: cidadeHub.cidade.nome
          };

          localStorage.setItem("indisponivel", this.indisponivel);
          loadingButton(this.$refs.btnBuscarCep, false);
        })
        .catch((error) => {
          console.log(error);
          this.$vToastify.warning(
            "Verifique se o cep está correto e tente novamente.",
            "Atenção"
          );
          loadingButton(this.$refs.btnBuscarCep, false);
        });
    },
    async buscarCidades() {
      return this[BUSCAR_CIDADES_DISPONIVEIS]().then((dados) => {
        return dados;
      });
    },
    alterarQuantidadeIpPublico(metodo) {
      if (metodo == "remove" && this.evento_contratacao.quantidade_ip_publico > 0) {
        this.evento_contratacao.quantidade_ip_publico--;
      } else if (metodo == "add") {
        this.evento_contratacao.quantidade_ip_publico++;
      }
    },
    async salvar() {
      if (!this.evento.id_evento) {
        this.evento.id_pessoa_criacao = this.cliente?.id_pessoa
      }

      // seta como submitted
      this.submitted = true;

      //sinaliza para os childs que foi feito o submit do form
      this.$v.$touch();

      /* significa que foi criado externamente */
      if (this.evento.id_pessoa_criacao && (this.$v.endereco.$invalid || this.$v.evento_contratacao.$invalid || this.$v.evento.$invalid)) {
        return;
      } else if (!this.evento.id_pessoa_criacao && this.$v.evento_contratacao.$invalid) {
        return;
      }
      // valida se o campo é valido

      loadingButton(this.$refs.btnAlterarTodosCampos, true);

      let url = `contratacao/criar`;
      let dados = {
        is_evento: true,
        evento_endereco: this.endereco,
        evento: this.evento,
        evento_contratacao: this.evento_contratacao,
        passo_formulario: ENDERECO
      };

      if (this.evento_contratacao.id_evento_contratacao) {
        url = `contratacao/alterar`;
        dados.id_contratacao = this.contratacao.id_contratacao;
        dados.evento_endereco.cidade = this.endereco.cidade.nome
      }

      if(localStorage.getItem('id_usuario_vendedor') != null){
        this.id_usuario_vendedor = localStorage.getItem("id_usuario_vendedor");
        dados.id_usuario_vendedor = this.id_usuario_vendedor;
      }

      await ApiService.post(url, dados).then(() => {
        this.$emit("evento-proximo-passo");
        loadingButton(this.$refs.btnAlterarTodosCampos, false);
      }).catch(() => {
        loadingButton(this.$refs.btnAlterarTodosCampos, false);
        this.$vToastify.warning(
          "Ocorreu um erro ao salvar os dados. Aguarde um instante e tente novamente.",
          "Atenção"
        );
      });

    },
    validarNumero(event,param) {
      let valor = event.target.value;
      if (valor < 0 || (valor != 0 && !Number.isInteger(parseFloat(valor)))) {
        if(param == "quantidade_dispositivos_wifi")
          this.evento_contratacao.quantidade_dispositivos_wifi = 0;
          else
          this.evento_contratacao.quantidade_dispositivos_cabo = 0;
      }
    }
  },
};
</script>

<style>
.div-endereco {
  margin-top: 3%;
}

.div-box-space {
  margin-top: 2%;
  height: 20px;
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check:checked+.btn-outline-danger {
  background-color: #cb2525;
  border-color: #cb2525;
  color: #fff;
}

.btn-check:checked+.btn-outline-success {
  background-color: #00ab92;
  border-color: #00ab92;
  color: #000;
}

.gap-7 {
  gap: 7px;
}

.w-max-25 {
  width: 10rem;
}

.btn-icon-custom {
  width: 4rem !important;
  height: 3.3rem !important;
}
</style>
