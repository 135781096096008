<template>
  <!-- begin:: Header -->
  <div class="bg-white">
    <div
      id="kt_header"
      ref="kt_header"
      class="align-items-center d-flex justify-content-between container"
    >
        <div class="">
          <a href="https://www.nicnet.com.br">
            <img src="media/logo.png" class="h-50px my-2" />
          </a>
        </div>
        <div class="">
          <a href="#" @click="logoutApp()"  class="font-size-h6">Sair</a>
        </div>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
// import { mapGetters } from "vuex";
// import KTTopbar from "@/views/header/Topbar.vue";
import {mapActions} from "vuex";
import {LOGOUT} from "@/core/services/store/auth/store/constant";

export default {
  name: "KTHeader",
  components: {
    // KTTopbar,
  },
  methods:{
    ...mapActions("auth", [LOGOUT]),
    logoutApp() {
      this[LOGOUT]().then(() => {
        let url = `/`;

        if(this.$route.params.slug){
          url = `/${this.$route.params.slug}`;
        }
        this.$router.push({ path: url, params: this.$route.params});
      });
    },
  }
};
</script>

<style>
</style>
