<template>
  <div>
    <div v-if="!finalizou">
      <div class="card card-custom">
        <div class="card-body">
          <div class="pb-5">
            <!--begin::Section-->

            <div>
              <h4 class="mb-10 mt-8 font-weight-bold text-dark text-center">
                Viva essa experiência!
              </h4>
              <h6 class="mb-10 font-weight-bold text-dark">
                Entraremos em contato para dar sequencia em sua contratação.
              </h6>
            </div>

            <div class="line-height-lg">
              <h6 class="font-weight-bolder mb-3">Sobre o evento:</h6>
              <div class="text-dark-50">
                  <div>Nome: {{ evento.nome }}</div>
                  <div>Data: {{ evento.data_inicio }} à {{ evento.data_fim }}</div>
                  <div>Endereço: {{ evento.endereco.logradouro }}</div>
                  <div>Número: {{ evento.endereco.numero }}</div>
                  <div>Bairro: {{ evento.endereco.bairro_string }}</div>
                  <div>Cidade: {{ evento.endereco.cidade.nome }} - {{ evento.endereco.cidade.estado.sigla }}</div>
              </div>
              <div class="separator separator-dashed my-5"></div>
            </div>

            <div class="line-height-lg">
              <h6 class="font-weight-bolder mb-3">Dados cadastrais:</h6>
              <div class="text-dark-50">
                <div>Nome: {{ cliente.razao_social }}</div>
                <div>Documento: {{ mascaraDocumento(cliente.cpf_cnpj) }}</div>
                <div v-if="!this.cliente.isEmpresa">
                  Data de nascimento: {{ cliente.data_nascimento }}
                </div>
                <div>RG/I.E: {{ cliente.rg_inscricao }}</div>
                <div v-if="!this.cliente.isEmpresa">
                  Nome Mãe: {{ cliente.nome_mae_responsavel }}
                </div>
              </div>
              <div class="separator separator-dashed my-5"></div>
            </div>


            <!--end::Section-->
            <!--begin::Section-->
            <h6 class="font-weight-bolder mb-3">Dados de contato:</h6>
            <div class="text-dark-50 line-height-lg">
              <div>Telefone: {{ formataTelefone(cliente.telefone1) }}</div>
              <div>Email: {{ cliente.email1 }}</div>
            </div>
            <div class="separator separator-dashed my-5"></div>

            <!--end::Section-->
            <!--begin::Section-->

            <div class="line-height-lg">
              <h6 class="font-weight-bolder mb-3">Endereço de cobrança:</h6>
              <div class="text-dark-50">
                <div>Endereço: {{ endereco.logradouro }}</div>
                <div>Número: {{ endereco.numero }}</div>
                <div>Bairro: {{ endereco.bairro }}</div>
                <div>Cidade: {{ endereco.cidade.nome }}</div>
                <div>UF: {{ endereco.cidade.estado.sigla }}</div>
                <div class="mt-3"></div>
                <small>* Sujeito a verificação de disponibilidade.</small>
              </div>
            </div>

            <div class="line-height-lg">
              <div class="separator separator-dashed my-5"></div>
              <h6 class="font-weight-bolder mb-3">Serviços:</h6>
              <div class="text-dark-50">
                <div>Quantidade de IP Público: {{ evento_contratacao.quantidade_ip_publico }}</div>
                <div>Quantidade dispositivos conectados via WIFI: {{ evento_contratacao.quantidade_dispositivos_wifi }}</div>
                <div>Quantidade dispositivos conectados via CABO: {{ evento_contratacao.quantidade_dispositivos_cabo }}</div>
                <div class="mt-3"></div>
              </div>
            </div>

            <div class="line-height-lg" v-if="evento_contratacao.nome_tecnico_responsavel && evento_contratacao.nome_tecnico_responsavel != ''">
              <div class="separator separator-dashed my-5"></div>
              <h6 class="font-weight-bolder mb-3">Técnico Responsável:</h6>
              <div class="text-dark-50">
                <div>Nome: {{ evento_contratacao.nome_tecnico_responsavel }}</div>
                <div>Telefone: {{ formataTelefone(evento_contratacao.telefone_tecnico_responsavel) }}</div>
                <div class="mt-3"></div>
              </div>
            </div>

            <div class="line-height-lg" v-if="contratacao.contratacao_servicos.lenght > 0">
              <div class="separator separator-dashed my-5"></div>
              <h6 class="font-weight-bolder mb-3">Serviços:</h6>
              <div class="text-dark-50" v-for="contratacao_servico in contratacao.contratacao_servicos"
                :key="contratacao_servico.id_servico">
                <span class="text-dark-50 line-height-lg">{{ contratacao_servico.servico.nome }} - </span>
                <span>
                  <span v-if="contratacao_servico.id_promocao" class="text-dark-50 line-height-lg">
                    R$ {{ formatarValor(contratacao_servico.valor - Number(contratacao_servico.valor_desconto)) }}
                    durante {{ contratacao_servico.duracao_desconto }} meses,
                    após R$ {{ formatarValor(contratacao_servico.valor) }}
                  </span>
                  <span v-else class="text-dark-50 line-height-lg">R$ {{ formatarValor(contratacao_servico.valor)
                  }}</span>
                </span>

                <div class="ml-2" v-if="contratacao_servico.contratacao_servicos_adicionais.length > 0"> Servicos
                  adicionais:</div>
                <div class="ml-2" v-for="servico_adicional in contratacao_servico.contratacao_servicos_adicionais"
                  v-bind:key="servico_adicional.id_contratacao_servico_adicional">
                  <span class="text-dark-50 line-height-lg">
                    {{ servico_adicional.quantidade }}x
                    {{ servico_adicional.pacote.descricao }} no valor de R$
                    {{ formatarValor(servico_adicional.quantidade * servico_adicional.pacote.valor) }}
                    por mês
                  </span>
                </div>

              </div>

              <div class="separator separator-dashed my-5"></div>
            </div>

            <div class="col-md-12 p-0">
              <div class="checkbox-list">
                <h5 class="mt-2">
                  Ao prosseguir com a contratação você confirma que está ciente
                  com os
                  <b><a target="_blank" href="https://nicnet.s3.sa-east-1.amazonaws.com/contratos/contrato-completo.pdf"
                      class="text-primary">termos do contrato da Nicnet.</a></b>
                </h5>
                <!--                </label>-->
              </div>
            </div>

            <div v-if="errorAcordo" class="text-danger">
              É necessário aceitar os termos para continuar
            </div>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-between">
          <button href="#" ref="btnVoltarPasso" class="btn btn-primary" @click="voltarPasso()">
            Voltar
          </button>
          <button class="btn btn-primary" @click="finalizarContratacao()" ref="btnFinalizarContratacao">
            Concluir
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="card card-custom">
        <div class="card-body">
          <div class="pb-5">
            <div class="text-center">
              <i class="fa fa-check icon-5x text-success"></i>
            </div>

            <div>
              <h4 class="mb-10 mt-8 font-weight-bold text-dark text-center">
                Viva essa experiência!
              </h4>

              <h6 class="mb-10 font-weight-bold text-dark text-center">
                Sua solicitação de contratação foi recebida e está em processamento, em breve você receberá nosso contato.<br><br>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  loadingButton,
  formatarMoedaSemCifrao,
  mascaraDocumento,
  formataTelefone,
} from "@/core/services/functionsGlobal/index";
// import moment from "moment";
import { mapActions } from "vuex";
import {
  ALTERAR_DADOS_CADASTRO,
  BUSCAR_DADOS_CLIENTE,
  BUSCAR_PLANOS_COMBOS,
} from "@/core/services/store/home/store/constant";
import { LOGOUT } from "@/core/services/store/auth/store/constant";
import { DADOS_PESSOAIS } from "@/core/Enums/passoFormulario";
// import CartaoCredito from "@/views/home/components/CartaoCredito";

export default {
  name: "PassoFinalizacao",
  props: ["contratacao", "cliente", "endereco"],
  data() {
    return {
      servicos: [],
      evento_contratacao: {
        id_contratacao: null,
        id_evento: null,
        id_evento_status: null,
        quantidade_dispositivos_wifi: null,
        quantidade_dispositivos_cabo: null,
        quantidade_ip_publico: null,
        valor: null,
        observacoes: null
      },
      evento: {
        id_evento: null,
        nome: "",
        data_inicio: null,
        data_fim: null,
        tipo_pagamento: 0,
        id_endereco: null,
        id_evento_status: null,
        slug: null,
        endereco: {
          id_endereco: null,
          logradouro: null,
          numero: null,
          cep: null,
          complemento: null,
          id_bairro: null,
          id_cidade: null,
          id_condominio: null,
          setor: null,
          bairro_string: null,
          condominio: null,
          bloco: null,
          apartamento: null,
          latitude: null,
          longitude: null,
          id_imovel_tamanho: null,
          id_imovel_tipo: null,
          cidade: {
            id_cidade: null,
            id_estado: null,
            nome: null,
            ibge: null,
            cep: null,
            created_at: null,
            updated_at: null,
            estado: {
              id_estado: null,
              nome: null,
              sigla: null,
            }
          }
        }
      },
      conheceu: "",
      acordo: true,
      errorAcordo: false,
      finalizou: false,
      escolhaTipoContratacao: null,
      urlFaturas: "",
    };
  },
  // components: {
  //   CartaoCredito,
  // },
  methods: {
    ...mapActions("home", [
      ALTERAR_DADOS_CADASTRO,
      BUSCAR_DADOS_CLIENTE,
      BUSCAR_PLANOS_COMBOS,
    ]),
    ...mapActions("auth", [LOGOUT]),
    formatarValor(valor) {
      return formatarMoedaSemCifrao(valor);
    },
    mascaraDocumento,
    formataTelefone,
    async voltarPasso() {
      loadingButton(this.$refs.btnVoltarPasso, true);
      await this[ALTERAR_DADOS_CADASTRO]({
        id_contratacao: this.contratacao.id_contratacao,
        passo_formulario:DADOS_PESSOAIS,
        finalizado: 0,
      })
        .then(() => {
          this.$emit("evento-proximo-passo");
          loadingButton(this.$refs.btnVoltarPasso, false);
        })
        .catch(() => {
          loadingButton(this.$refs.btnVoltarPasso, false);
          this.$vToastify.error(
            "Ocorreu um erro ao retornar para a escolha do plano. Aguarde um instante e tente novamente.",
            "Atenção"
          );
        });
    },
    finalizarContratacao() {
      loadingButton(this.$refs.btnFinalizarContratacao, true);
      // clicou em continuar e está de acordo com os termos
      this[ALTERAR_DADOS_CADASTRO]({
        finalizado: 1,
        id_contratacao: this.contratacao.id_contratacao,
      }).then(() => {
        // window.dataLayer = window.dataLayer || [];
        // window.dataLayer.push({
        //   'event': 'contratacao_finalizada',
        //   'contratacao_id': this.contratacao.id
        // });
        loadingButton(this.$refs.btnFinalizarContratacao, false);

        this.finalizou = true;

        if (
          localStorage.getItem("id_usuario_vendedor") != null &&
          localStorage.getItem("redirecionar") == 1
        ) {
          this[LOGOUT]();
          window.location.href = `https://nicon.nicnet.com.br/contratacoes/${this.contratacao.id_contratacao}`;
        } else {
          this[LOGOUT]();
        }
      });
    },
  },
  created() {
    if (!this.cliente.email1)
      this.cliente.email1 = 'Não informado';

    this.evento = this.contratacao.eventos[0];
    this.evento_contratacao = this.contratacao.eventos[0].pivot;

  },
};
</script>

<style>
.div-seus-dados {
  margin-top: 2%;
}
</style>
