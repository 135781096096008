var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"pb-5"},[_vm._m(0),_c('div',[_c('h4',{staticClass:"mb-10 mt-8 font-weight-bold text-dark text-center"},[_vm._v(" Eu ainda não consigo atender seu endereço. ")]),((this.contratacao.endereco.condominio_cadastrado !== undefined && this.contratacao.endereco.condominio_cadastrado != null) || _vm.contratacao.endereco.condominio == null || _vm.contratacao.endereco.condominio.length === 0 || _vm.dados_enviados === true)?_c('h6',{staticClass:"mb-10 font-weight-bold text-dark text-center"},[_vm._v(" Já salvei seu contato, te aviso assim que houver disponibilidade. ")]):_c('div',[_c('h6',{staticClass:"mb-10 font-weight-bold text-dark text-center"},[_vm._v(" Ainda não atendo seu endereço. Para eu verificar a possibilidade de estruturar a rede no seu condomínio "+_vm._s(_vm.contratacao.endereco.condominio)+", preciso do contato do síndico ou responsável. ")]),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-md-4 offset-md-2"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Nome do síndico")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nome_sindico),expression:"nome_sindico"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.submitted && _vm.$v.nome_sindico.$error,
                    },attrs:{"type":"text","id":"nome_sindico","autocomplete":"off","name":"nome_sindico"},domProps:{"value":(_vm.nome_sindico)},on:{"input":function($event){if($event.target.composing)return;_vm.nome_sindico=$event.target.value}}}),(_vm.submitted && _vm.$v.nome_sindico.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Preencha o campo corretamente ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Telefone do síndico")]),_c('TheMask',{staticClass:"form-control",class:{
                            'is-invalid': _vm.submitted && _vm.$v.telefone_sindico.$error,
                    },attrs:{"type":"text","id":"telefone_sindico","autocomplete":"off","name":"telefone_sindico","mask":['(##) #####-####'],"masked":true},model:{value:(_vm.telefone_sindico),callback:function ($$v) {_vm.telefone_sindico=$$v},expression:"telefone_sindico"}}),(_vm.submitted && _vm.$v.telefone_sindico.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Preencha o campo corretamente ")]):_vm._e()],1)])]),_vm._m(2),_c('div',{staticClass:"col-md-4 offset-md-2"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Nome do zelador")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nome_zelador),expression:"nome_zelador"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.submitted && _vm.$v.nome_zelador.$error,
                    },attrs:{"type":"text","id":"nome_zelador","autocomplete":"off","name":"nome_sindico"},domProps:{"value":(_vm.nome_zelador)},on:{"input":function($event){if($event.target.composing)return;_vm.nome_zelador=$event.target.value}}}),(_vm.submitted && _vm.$v.nome_zelador.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Preencha o campo corretamente ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Telefone do zelador")]),_c('TheMask',{staticClass:"form-control",class:{
                            'is-invalid': _vm.submitted && _vm.$v.telefone_zelador.$error,
                    },attrs:{"type":"text","id":"telefone_zelador","autocomplete":"off","name":"telefone_zelador","mask":['(##) #####-####'],"masked":true},model:{value:(_vm.telefone_zelador),callback:function ($$v) {_vm.telefone_zelador=$$v},expression:"telefone_zelador"}}),(_vm.submitted && _vm.$v.telefone_zelador.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Preencha o campo corretamente ")]):_vm._e()],1)])]),_c('div',{staticClass:"col-12 text-center mt-8"},[_c('button',{ref:"btnSemInteresse",staticClass:"btn btn-danger m-2",on:{"click":function($event){return _vm.finalizar(true)}}},[_vm._v("NÃO TENHO INTERESSE ")]),_c('button',{ref:"btnEnviarDados",staticClass:"btn btn-primary m-2",on:{"click":function($event){return _vm.salvarDadosCondominio()}}},[_vm._v("ENVIAR DADOS DE CONTATO ")])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('i',{staticClass:"fa fa-times icon-5x text-danger"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mt-8 mb-4 text-center"},[_c('h5',[_vm._v("Preencha os dados do síndico:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mt-8 mb-4 text-center"},[_c('h5',[_vm._v("Preencha os dados do zelador:")])])
}]

export { render, staticRenderFns }