<template>
  <div class="bg-secondary min-vh-100">
    <Header v-show="!tirar_header"></Header>
    <div class="container py-12">
      <div class="row">
        <div class="col-xl-12" v-if="carregando == false">
          <MiniDescricaoContratacao v-if="contratacao.id_contratacao != null &&
            (contratacao.passo_formulario == escolha_plano || contratacao.passo_formulario == dados_pessoais) &&
            contratacao.finalizado == 0
            " v-bind:contratacao="contratacao" v-bind:cliente="cliente" v-on:evento-proximo-passo="passoFormulario">
          </MiniDescricaoContratacao>
          <div>
            <PassoEnderecoEvento v-if="contratacao.passo_formulario == endereco_evento"
              v-on:evento-proximo-passo="passoFormulario" v-bind:app_hash="app_hash" v-bind:contratacao="contratacao"
              v-bind:cliente="cliente"></PassoEnderecoEvento>
            <PassoEndereco v-if="contratacao.passo_formulario == endereco" v-on:evento-proximo-passo="passoFormulario"
              v-bind:app_hash="app_hash" v-bind:contratacao="contratacao" v-bind:cliente="cliente"></PassoEndereco>
            <PassoDadosPessoais v-if="contratacao.passo_formulario == dados_pessoais"
              v-on:evento-proximo-passo="passoFormulario" v-bind:app_hash="app_hash" v-bind:contratacao="contratacao"
              v-bind:cliente="cliente"></PassoDadosPessoais>
            <PassoEscolhaPlano v-else-if="contratacao.passo_formulario == escolha_plano" v-bind:contratacao="contratacao"
              v-bind:cliente="cliente" v-on:evento-proximo-passo="passoFormulario"></PassoEscolhaPlano>
            <PassoFinalizacao v-else-if="contratacao.passo_formulario == finalizado" v-bind:contratacao="contratacao"
              v-bind:endereco="contratacao.endereco" v-bind:cliente="cliente" v-on:evento-proximo-passo="passoFormulario">
            </PassoFinalizacao>
            <PassoGeolocalizacao v-else-if="contratacao.passo_formulario == geolocalizacao"
              v-bind:contratacao="contratacao" v-bind:endereco="contratacao.endereco" v-bind:cliente="cliente"
              v-on:evento-proximo-passo="passoFormulario"></PassoGeolocalizacao>
            <PassoFinalizacaoProspect v-else-if="contratacao.passo_formulario == finalizadoProspect"
              v-bind:contratacao="contratacao" v-bind:endereco="contratacao.endereco" v-bind:cliente="cliente"
              v-on:evento-proximo-passo="passoFormulario"></PassoFinalizacaoProspect>
          </div>
        </div>
        <div v-else class="col-xl-12 mt-5">
          <div class="row justify-content-center">
            <div class="spinner spinner-lg spinner-primary"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MiniDescricaoContratacao from "@/views/home/components/MiniDescricaoContratacao.vue";
import Header from "@/views/header/Header.vue";
import PassoEndereco from "@/views/home/components/PassoEndereco.vue";
import PassoEnderecoEvento from "@/views/home/components/PassoEnderecoEvento.vue";
import PassoDadosPessoais from "@/views/home/components/PassoDadosPessoais";
import PassoEscolhaPlano from "@/views/home/components/PassoEscolhaPlano.vue";
import PassoFinalizacao from "@/views/home/components/PassoFinalizacao.vue";
import PassoFinalizacaoProspect from "@/views/home/components/PassoFinalizacaoProspect.vue";
//import PassoPagamento from "@/views/home/components/PassoPagamento.vue";
import PassoGeolocalizacao from "@/views/home/components/PassoGeolocalizacao";

import ApiService from "@/core/services/api/api.service";
import {
  formatarDataNascimento,
  formataTelefone,
} from "@/core/services/functionsGlobal/index";
import {
  ENDERECO,
  DADOS_PESSOAIS,
  ESCOLHA_PLANO,
  PAGAMENTO,
  FINALIZADO,
  VALIDACAO_GEOLOCALIZACAO,
  FINALIZADO_PROSPECT,
  ENDERECO_EVENTO
} from "@/core/Enums/passoFormulario";


export default {
  data() {
    return {
      tirar_header: false,
      app_hash: "",
      vendedor_external_id: null,
      carregando: true,
      evento: {
        id_evento: null,
        nome: null, 
        data_inicio: null, 
        data_fim: null, 
        tipo_pagamento: null, 
        id_endereco: null,
        id_evento_status: null
      },
      contratacao: {
        id_contratacao: null,
        id_pessoa_cadastro: null,
        id_endereco: null,
        passo_formulario: ENDERECO_EVENTO,
        finalizado: null,
        importado: null,
        created_at: "",
        updated_at: "",
        rg_inscricao: "",
        data_nascimento: "",
        sexo: "",
        email: "",
        nome_mae_responsavel: "",
        como_nos_conheceu: "",
        dia_pagamento: null,
        servicos: [],
        endereco: {
          id_endereco: null,
          cep: null,
          logradouro: "",
          numero: null,
          bairro: "",
          id_bairro: null,
          complemento: "",
          bloco: "",
          apartamento: "",
          condominio: "",
          id_condominio: "",
          cidade: "",
          id_cidade: null,
          uf: "",
        },
      },
      cliente: {
        cpf_cnpj: null,
        data_nascimento: null,
        email1: null,
        genero: null,
        id_pessoa: null,
        nome_fantasia: null,
        nome_mae_responsavel: null,
        profissao: null,
        razao_social: null,
        rg_data_emissao: null,
        rg_inscricao: null,
        rg_orgao_emissor: null,
        telefone1: null,
        telefone2: null,
        sem_email: null,
      },
      endereco: ENDERECO,
      endereco_evento: ENDERECO_EVENTO,
      dados_pessoais: DADOS_PESSOAIS,
      escolha_plano: ESCOLHA_PLANO,
      pagamento: PAGAMENTO,
      finalizado: FINALIZADO,
      geolocalizacao: VALIDACAO_GEOLOCALIZACAO,
      finalizadoProspect: FINALIZADO_PROSPECT,
      slug_evento:null
    };
  },
  components: {
    PassoGeolocalizacao,
    PassoDadosPessoais,
    Header,
    MiniDescricaoContratacao,
    // PassoPagamento,
    PassoEndereco,
    PassoEnderecoEvento,
    PassoEscolhaPlano,
    PassoFinalizacao,
    PassoFinalizacaoProspect
  },
  methods: {
    passoFormulario() {
      this.carregando = true;
      this.buscarContratacoes();
      // this.contratacao.passo_formulario = dados.passo_formulario;
    },
    async buscarContratacoes() {
      ApiService.get(`contratacao/pendentes?slug_evento=${this.$route.params.slug ?? 'padrao'}&is_evento=1`).then(({data}) => {
        if (data.contratacao) {
          this.contratacao = data.contratacao;
        }

        if (data.pessoa) {
          this.cliente = data.pessoa;
          this.cliente.telefone1 = formataTelefone(
            this.cliente.telefone1 ?? ""
          );

          if (this.cliente.cpf_cnpj.length > 11) {
            this.cliente.isEmpresa = true;
          } else {
            this.cliente.data_nascimento = formatarDataNascimento(
              false,
              this.cliente.data_nascimento
            );
          }

          if(this.contratacao.eventos && this.contratacao.eventos?.length > 0){
            this.contratacao.eventos = this.contratacao?.eventos.map((evento) => {
              evento.data_inicio = formatarDataNascimento(false,evento.data_inicio);
              evento.data_fim = formatarDataNascimento(false,evento.data_fim);
              return evento;
            });
          }
        }
        this.carregando = false;
      });
    },
  },
  created() {
    this.buscarContratacoes();
  },
};
</script>

<style scoped>
.div-carregando {
  min-height: 100%;
  /* Fallback for browsers do NOT support vh unit */
  min-height: 80vh;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
