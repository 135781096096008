<template>
  <div class="kt-portlet">
    <div class="card card-custom gutter-b descricao-card">
      <div
        class="card-body d-flex align-items-center justify-content-between flex-wrap"
      >
        <div class="mr-2">
          <h5 class="font-weight-bolder">{{ cliente.razao_social }}, você está contratando para o evento {{ contratacao.eventos[0].nome }}</h5>
          <div class="text-dark-50 font-size-lg mt-2">
            <span>O endereço de cobrança é: 
              {{ contratacao.endereco.logradouro }},
              {{ contratacao.endereco.numero }},
              {{ contratacao.endereco.bairro }}
            </span>
          </div>
        </div>
        <div class="btn-alterar-endereco">
          <button
          href="#"
          ref="btnAlterarEndereco"
          class="btn btn-primary "
          @click="alterarPasso()"
          v-show="contratacao.passo_formulario != 0"
        >
          Alterar endereço
        </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { ALTERAR_DADOS_CADASTRO } from "@/core/services/store/home/store/constant";
import { loadingButton } from "@/core/services/functionsGlobal/index";
export default {
  name: "MiniDescricaoContratacao",
  props: ["contratacao", "cliente"],
  methods: {
    ...mapActions("home", [ALTERAR_DADOS_CADASTRO]),
    async alterarPasso() {
      loadingButton(this.$refs.btnAlterarEndereco, true);
      await this[ALTERAR_DADOS_CADASTRO]({
        id_contratacao: this.contratacao.id_contratacao,
        passo_formulario: 1,
      })
        .then(() => {
          this.$emit("evento-proximo-passo");
          loadingButton(this.$refs.btnAlterarEndereco, false);
        })
        .catch(() => {
          loadingButton(this.$refs.btnAlterarEndereco, false);

          this.$vToastify.error(
            "Ocorreu um erro ao alterar. Aguarde um instante e tente novamente!",
            "Atenção"
          );
        });
    },
  },
};
</script>

<style>
@media (max-width: 600px)
{
  .btn-alterar-endereco
   {
    margin-top: 25px;
   }
}
/* .descricao-card{
    height: 40px;
    width: 15%;
} */
</style>
