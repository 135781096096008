<template>
  <div>
    <div>
      <div class="card card-custom">
        <div class="card-body">
          <div class="pb-5">
            <div class="text-center">
              <i class="fa fa-question icon-5x text-primary"></i>
            </div>

            <div>
              <h2 class="mb-10 mt-8 font-weight-bold text-dark text-center">Vamos fazer uma nova verificação?</h2>
              <h4 class="mb-10 mt-8 font-weight-bold text-dark text-center">
                Preciso fazer uma verificação adicional no seu endereço para confirmar a disponibilidade.
              </h4>

              <h4 class="mb-10 font-weight-bold text-dark text-center">
                Caso você esteja no endereço de instalação no momento, você pode compartilhar a sua localização?
              </h4>

              <div class="text-center">
                <button class="btn btn-success m-2" @click="compartilharLocalizacao()" ref="btnLocalizacao">Sim, estou
                  no endereço que
                  informei
                </button>
                <button class="btn btn-primary m-2" @click="continuar()" ref="btnNaoEstou">Não estou</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {LOGOUT} from "@/core/services/store/auth/store/constant";
import {ALTERAR_DADOS_CADASTRO} from "@/core/services/store/home/store/constant";
import ApiService from "@/core/services/api/api.service";
import {loadingButton} from "@/core/services/functionsGlobal";
import {
  FINALIZADO_PROSPECT
} from "@/core/Enums/passoFormulario";

export default {
  name: "PassoGeolocalizacao",
  "props": ["contratacao"],
  data() {
    return {
      prospect: FINALIZADO_PROSPECT
    };
  },
  methods: {
    ...mapActions("auth", [LOGOUT]),
    ...mapActions("home", [ALTERAR_DADOS_CADASTRO]),
    continuar() {
      this[ALTERAR_DADOS_CADASTRO]({
        passo_formulario: this.prospect,
        id_contratacao: this.contratacao.id_contratacao,
      });
      this.$emit("evento-proximo-passo");
    },
    compartilharLocalizacao() {

      loadingButton(this.$refs.btnLocalizacao, true);
      loadingButton(this.$refs.btnNaoEstou, true);

      navigator.geolocation.getCurrentPosition(
          position => {
            ApiService.get(`contratacao/geolocalizacao?id_contratacao=${this.contratacao.id_contratacao}&latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`)
                .then(() => {
                  this.$emit("evento-proximo-passo");
                  loadingButton(this.$refs.btnLocalizacao, false);
                  loadingButton(this.$refs.btnNaoEstou, false);
                }).catch(() => {
              loadingButton(this.$refs.btnLocalizacao, false);
              loadingButton(this.$refs.btnNaoEstou, false);
              this.$vToastify.warning(
                  "Ocorreu um erro ao salvar os dados. Aguarde um instante e tente novamente.",
                  "Atenção"
              );
            });
          },
          () => {
            this[ALTERAR_DADOS_CADASTRO]({
              passo_formulario: this.prospect,
              id_contratacao: this.contratacao.id_contratacao,
            });
            this.$emit("evento-proximo-passo");
          },
      )
    },
  },
  created() {

  },
};
</script>
