<template>
  <div>
    <div>
      <div class="card card-custom">
        <div class="card-body">
          <div class="pb-5">
            <div class="text-center">
              <i class="fa fa-times icon-5x text-danger"></i>
            </div>

            <div>
              <h4 class="mb-10 mt-8 font-weight-bold text-dark text-center">
                Eu ainda não consigo atender seu endereço.
              </h4>

              <h6 class="mb-10 font-weight-bold text-dark text-center"
                  v-if="(this.contratacao.endereco.condominio_cadastrado !== undefined && this.contratacao.endereco.condominio_cadastrado != null) || contratacao.endereco.condominio == null || contratacao.endereco.condominio.length === 0 || dados_enviados === true">
                Já salvei seu contato, te aviso assim que houver disponibilidade.
              </h6>
              <div v-else>
                <h6 class="mb-10 font-weight-bold text-dark text-center">
                  Ainda não atendo seu endereço. Para eu verificar a possibilidade de estruturar a rede no seu condomínio {{ contratacao.endereco.condominio }}, preciso do contato do síndico ou responsável.
                </h6>
                
                <div class="row">
                  <div class="col-12 mt-8 mb-4 text-center">
                    <h5>Preencha os dados do síndico:</h5>
                  </div>
                  <div class="col-md-4 offset-md-2">
                    <div class="form-group">
                      <div class="form-group">
                        <label>Nome do síndico</label>
                        <input
                            type="text"
                            class="form-control"
                            id="nome_sindico"
                            autocomplete="off"
                            name="nome_sindico"
                            v-model="nome_sindico"
                            :class="{
                              'is-invalid': submitted && $v.nome_sindico.$error,
                      }"
                        />
                        <div
                            v-if="submitted && $v.nome_sindico.$error"
                            class="invalid-feedback"
                        >
                          Preencha o campo corretamente
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <div class="form-group">
                        <label>Telefone do síndico</label>
                        <TheMask
                            type="text"
                            class="form-control"
                            id="telefone_sindico"
                            autocomplete="off"
                            name="telefone_sindico"
                            v-model="telefone_sindico"
                            :mask="['(##) #####-####']"
                            :masked="true"
                            :class="{
                              'is-invalid': submitted && $v.telefone_sindico.$error,
                      }"
                        ></TheMask>
                        <div
                            v-if="submitted && $v.telefone_sindico.$error"
                            class="invalid-feedback"
                        >
                          Preencha o campo corretamente
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-8 mb-4 text-center">
                    <h5>Preencha os dados do zelador:</h5>
                  </div>
                  <div class="col-md-4 offset-md-2">
                    <div class="form-group">
                      <div class="form-group">
                        <label>Nome do zelador</label>
                        <input
                            type="text"
                            class="form-control"
                            id="nome_zelador"
                            autocomplete="off"
                            name="nome_sindico"
                            v-model="nome_zelador"
                            :class="{
                              'is-invalid': submitted && $v.nome_zelador.$error,
                      }"
                        />
                        <div
                            v-if="submitted && $v.nome_zelador.$error"
                            class="invalid-feedback"
                        >
                          Preencha o campo corretamente
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <div class="form-group">
                        <label>Telefone do zelador</label>
                        <TheMask
                            type="text"
                            class="form-control"
                            id="telefone_zelador"
                            autocomplete="off"
                            name="telefone_zelador"
                            v-model="telefone_zelador"
                            :mask="['(##) #####-####']"
                            :masked="true"
                            :class="{
                              'is-invalid': submitted && $v.telefone_zelador.$error,
                      }"
                        ></TheMask>
                        <div
                            v-if="submitted && $v.telefone_zelador.$error"
                            class="invalid-feedback"
                        >
                          Preencha o campo corretamente
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 text-center mt-8">
                    <button class="btn btn-danger m-2" @click="finalizar(true)" ref="btnSemInteresse">NÃO TENHO INTERESSE
                    </button>
                    <button class="btn btn-primary m-2" @click="salvarDadosCondominio()" ref="btnEnviarDados">ENVIAR
                      DADOS DE CONTATO
                    </button>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {LOGOUT} from "@/core/services/store/auth/store/constant";
import {ALTERAR_DADOS_CADASTRO} from "@/core/services/store/home/store/constant";
import ApiService from "@/core/services/api/api.service";
import {loadingButton} from "@/core/services/functionsGlobal";

export default {
  name: "PassoFinalizacaoProspect",
  "props": ["contratacao"],
  data() {
    return {
      submitted: false,
      nome_sindico: "",
      telefone_sindico: "",
      nome_zelador: "",
      telefone_zelador: "",
      dados_enviados: false

    };
  },
  methods: {
    ...mapActions("auth", [LOGOUT]),
    ...mapActions("home", [ALTERAR_DADOS_CADASTRO]),
    salvarDadosCondominio() {

      this.submitted = true;

      if (
          this.nome_sindico == "" &&
          this.telefone_sindico == "" &&
          this.nome_zelador == "" &&
          this.telefone_zelador == "") {
        this.$vToastify.error(
            "Preencha os dados do síndico ou do zelador",
            "Atenção"
        );
        return;
      }

      if ((this.nome_sindico == "" && this.telefone_sindico != "") || (this.nome_sindico != "" && this.telefone_sindico == "")) {
        this.$vToastify.error(
            "Preencha o nome e telefone do síndico",
            "Atenção"
        );
        return;
      }

      if ((this.nome_zelador == "" && this.telefone_zelador != "") || (this.nome_zelador != "" && this.telefone_zelador == "")) {
        this.$vToastify.error(
            "Preencha o nome e telefone do zelador",
            "Atenção"
        );
        return;
      }

      loadingButton(this.$refs.btnEnviarDados, true);
      loadingButton(this.$refs.btnSemInteresse, true);

      ApiService.post(`contratacao/salvar-dados-condominio`, {
        nome_sindico: this.nome_sindico,
        telefone_sindico: this.telefone_sindico,
        nome_zelador: this.nome_zelador,
        telefone_zelador: this.telefone_zelador,
        id_contratacao: this.contratacao.id_contratacao
      })
          .then(() => {
            this.finalizar();
          }).catch(() => {
        loadingButton(this.$refs.btnEnviarDados, false);
        this.$vToastify.warning(
            "Ocorreu um erro ao salvar os dados. Aguarde um instante e tente novamente.",
            "Atenção"
        );
      });

    },
    finalizar(loading = false) {

      this.dados_enviados = true;

      if (loading) {
        loadingButton(this.$refs.btnSemInteresse, true);
        loadingButton(this.$refs.btnEnviarDados, true);
      }

      this[ALTERAR_DADOS_CADASTRO]({
        finalizado: 1,
        id_contratacao: this.contratacao.id_contratacao,
      });
      this[LOGOUT]();


    }
  },

  created() {
    if ((this.contratacao.endereco.condominio_cadastrado !== undefined && this.contratacao.endereco.condominio_cadastrado != null) || this.contratacao.endereco.condominio == null || this.contratacao.endereco.condominio.length === 0) {
      this.finalizar()
    }
  },
};
</script>
