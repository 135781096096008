<template>
  <div>
    <div class="card card-custom gutter-b descricao-card">
      <div class="
          card-body
          d-flex
          align-items-center
          justify-content-between
          flex-wrap
        ">
        <div class="mr-2">
          <h5 class="font-weight-bolder">
            Olá {{ cliente.razao_social }}, você está contratando com o
            documento
            {{ mascaraDocumento(cliente.cpf_cnpj) }}
          </h5>
          <div class="text-dark-50 font-size-lg mt-3">
            <span>
              Quer contratar com outro documento?
              <b class="text-danger">
                <button @click="logoutApp()" class="btn-link text-danger font-weight-bolder">
                  Sair
                </button>
              </b>
            </span>
            <br />
            <div class="mt-2"></div>
            <span class="text-dark-50 font-size-lg">
              Precisa atualizar o nome?
              <b class="text-dark">
                <button class="btn-link font-weight-bolder" @click="() => (editarNome = true)">
                  Alterar
                </button>
              </b>
            </span>
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-1" v-show="editarNome">
        <div class="form-group">
          <label>Nome</label>
          <div class="input-group">
            <input type="text" class="form-control" autocomplete="off" maxlength="100" v-model="cliente.razao_social"
              @blur="alterarDadosCadastro('razao_social', cliente.razao_social)" :class="{
              'is-invalid': submitted && $v.cliente.razao_social.$error,
            }" />
            <div class="input-group-append">
              <button class="btn btn-primary" type="button" @click="() => (editarNome = false)">
                Salvar
              </button>
            </div>
          </div>
          <div v-if="submitted && $v.cliente.razao_social.$error" class="invalid-feedback">
            Preencha o campo corretamente
          </div>
        </div>
      </div>
    </div>
    <div class="card card-custom">
      <h6 class="text-center pt-5 pl-2 pr-2">
        Informe o CEP do endereço de cobrança
      </h6>

      <div class="kt-form__section kt-form__section--first card-body">
        <div class="row">
          <div class="col-lg-4 offset-lg-4 mb-5">
            <div class="input-group">
              <TheMask id="cep" :mask="['#####-###']" name="cep" autocomplete="off" v-model="endereco.cep"
                @blur.native="buscaCep" :class="{
              'is-invalid': submitted && $v.endereco.cep.$error,
            }" class="form-control"></TheMask>

              <div class="input-group-append">
                <button class="btn btn-primary" ref="btnBuscarCep" type="button" @click="buscaCep">
                  Buscar endereço
                </button>
              </div>
              <div v-if="submitted && $v.endereco.cep.$error" class="invalid-feedback">
                Preencha o campo corretamente
              </div>
            </div>
          </div>
        </div>

        <div v-if="!disponivelCidade">
          <div v-if="buscouCep">
            <p class="lead">
              Eu ainda não tenho disponibilidade para sua cidade, mas, estou em
              constante expansão, então me acompanhe nas redes sociais para
              ficar por dentro das novidades.
            </p>
            <a href="https://facebook.com/nicnetfibraoptica/" target="_blank" class="btn btn-facebook mr-2"><i
                class="socicon-facebook"></i> Facebook</a>
            <a href="https://instagram.com/nicnetfibraoptica/" target="_blank" class="btn btn-instagram"><i
                class="socicon-instagram"></i> Instagram</a>
          </div>
        </div>

        <div class="div-endereco" v-else>
          <div class="row">
            <div class="col-12 mb-4 text-center atendimento_cidade">
              <h3 class="mb-0">
                <span id="cidade_html"></span>{{ endereco.cidade.nome }} /
                {{ endereco.cidade.estado.sigla }}
                <span id="uf_html"></span>
              </h3>
            </div>
            <div class="div-box-space"></div>
            <div class="col-12 col-md-8">
              <div class="form-group">
                <label>Endereço</label>
                <input type="text" class="form-control" id="endereco" autocomplete="off" name="endereco"
                  v-model="endereco.logradouro" :disabled="!this.permiteAlterarEndereco" @blur="alterarDadosCadastro('logradouro', endereco.logradouro)
              " :class="{
              'is-invalid': submitted && $v.endereco.logradouro.$error,
            }" />
                <div v-if="submitted && $v.endereco.logradouro.$error" class="invalid-feedback">
                  Preencha o campo corretamente
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="form-group">
                  <label>Número</label>
                  <input type="tel" class="form-control" id="numero" autocomplete="off" name="numero"
                    v-model="endereco.numero" @blur="alterarDadosCadastro('numero', endereco.numero)" :class="{
              'is-invalid': submitted && $v.endereco.numero.$error,
            }" />
                  <div v-if="submitted && $v.endereco.numero.$error" class="invalid-feedback">
                    Preencha o campo corretamente
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Bairro</label>
                <input type="text" class="form-control" autocomplete="off" name="bairro" maxlength="30"
                  v-model="endereco.bairro" @blur="alterarDadosCadastro('bairro', endereco.bairro)" :class="{
              'is-invalid': submitted && $v.endereco.bairro.$error,
            }" />
                <!-- <select
                  class="form-control"
                  v-model="endereco.bairro"
                  name="bairro"
                  placeholder=""
                  v-on:blur="alterarDadosCadastro('bairro', cliente.bairro)"
                >
                  <option
                    :value="bairro.idbairro"
                    v-for="bairro in listaBairros"
                    :key="bairro.idbairro"
                    :selected="bairro.idbairro == endereco.bairro_id"
                  >
                    {{ bairro.bairro }}
                  </option>
                </select> -->
                <div v-if="submitted && $v.endereco.bairro.$error" class="invalid-feedback">
                  Preencha o campo corretamente
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Condomínio</label>
                <input type="text" class="form-control" id="condominio" autocomplete="off" name="condominio"
                  maxlength="30" v-model="endereco.condominio" @blur="alterarDadosCadastro('condominio', endereco.condominio)
              " />
              </div>
            </div>

            <div class="col-12 col-md-4">
              <div class="form-group">
                <label>Apartamento</label>
                <input type="text" class="form-control" id="apartamento" autocomplete="off" name="apartamento"
                  maxlength="4" v-model="endereco.apartamento" @blur="alterarDadosCadastro('apartamento', endereco.apartamento)
              " />
              </div>
            </div>

            <div class="col-12 col-md-4">
              <div class="form-group">
                <label>Bloco</label>
                <input type="text" class="form-control" id="bloco" autocomplete="off" name="bloco" maxlength="5"
                  v-model="endereco.bloco" @blur="alterarDadosCadastro('bloco', endereco.bloco)" />
              </div>
            </div>

            <div class="col-12 col-md-4">
              <div class="form-group">
                <label>Complemento</label>
                <input type="text" class="form-control" id="complemento" autocomplete="off" name="complemento"
                  maxlength="30" v-model="endereco.complemento" @blur="alterarDadosCadastro('complemento', endereco.complemento)
              " />
              </div>
            </div>

            <!-- <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Tipo do seu Imóvel</label>
                <select
                  class="form-control"
                  v-model="endereco.id_imovel_tipo"
                  name="id_imovel_tipo"
                  placeholder=""
                  @change="
                    alterarDadosCadastro(
                      'id_imovel_tipo',
                      endereco.id_imovel_tipo
                    )
                  "
                  :class="{
                    'is-invalid':
                      submitted && $v.endereco.id_imovel_tipo.$error,
                  }"
                >
                  <option value="" disabled selected></option>
                  <option
                    :value="tipoImovel.id_imovel_tipo"
                    v-for="tipoImovel in tiposImoveis"
                    :key="tipoImovel.id_imovel_tipo"
                    :selected="
                      tipoImovel.id_imovel_tipo == endereco.id_imovel_tipo
                    "
                  >
                    {{ tipoImovel.nome }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Área construida do imóvel</label>
                <select
                    class="form-control"
                    v-model="endereco.id_imovel_tamanho"
                    name="id_imovel_tamanho"
                    placeholder=""
                    @change="
                    alterarDadosCadastro(
                      'id_imovel_tamanho',
                      endereco.id_imovel_tamanho
                    )
                  "
                    :class="{
                    'is-invalid':
                      submitted && $v.endereco.id_imovel_tamanho.$error,
                  }"
                >
                  <option value="" disabled selected></option>
                  <option
                      :value="tamanhoImovel.id_imovel_tamanho"
                      v-for="tamanhoImovel in tamanhosImoveis"
                      :key="tamanhoImovel.id_imovel_tamanho"
                      :selected="
                      tamanhoImovel.id_imovel_tamanho ==
                      endereco.id_imovel_tamanho
                    "
                  >
                    {{ tamanhoImovel.nome }}
                  </option>
                </select>
              </div>
            </div> -->
          </div>
        </div>
        <div class="card-footer d-flex justify-content-between p-0 pt-5 mt-10">
          <button type="button" class="btn btn-secondary" @click="voltarPasso()">Voltar</button>
          <button type="button" class="btn btn-primary mr-2" ref="btnAlterarTodosCampos" @click="alterarTodosCampos()"
            v-if="!indisponivel">
            Continuar
          </button>
          <!-- <button type="reset" class="btn btn-secondary">Cancel</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as cep from "cep-promise";
import { required, maxLength } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api/api.service";
import moment from "moment";
import {
  // retirarMascaraCep,
  loadingButton,
  validaCep,
  IsEmailValido,
  formatarDataNascimento,
  // verificaBairro
} from "@/core/services/functionsGlobal";
import { mapActions } from "vuex";
import {
  mensagemCumprimento,
  mascaraDocumento,
  retiraMascaraTelefone,
} from "@/core/services/functionsGlobal";

import {
  BUSCAR_CIDADES_DISPONIVEIS,
  BUSCAR_BAIRROS_DISPONIVEIS,
  ALTERAR_DADOS_CADASTRO,
} from "@/core/services/store/home/store/constant";
import { LOGOUT } from "@/core/services/store/auth/store/constant";
import { TheMask } from "vue-the-mask";
import { DADOS_PESSOAIS, ENDERECO_EVENTO } from "@/core/Enums/passoFormulario";
export default {
  name: "PassoEndereco",
  data() {
    return {
      endereco: {},
      tamanhosImoveis: [],
      tiposImoveis: [],
      submitted: "",
      disponivelCidade: false, //comeca como true
      indisponivel: true, //comeca como true
      buscouCep: false,
      permiteAlterarEndereco: false,
      listaBairros: [],
      editarNome: false,
      isEmpresa: false,
      origemERP: 0,
      updated: 0,
    };
  },
  props: ["contratacao", "cliente", "app_hash"],
  validations: {
    endereco: {
      cep: {
        required,
        validaCep: (cep) => validaCep(cep),
      },
      logradouro: {
        required,
      },
      numero: {
        required,
      },
      bairro: {
        required,
      },
      // id_imovel_tamanho: {
      //   required,
      // },
      // id_imovel_tipo: {
      //   required,
      // },
    },
    cliente: {
      rg_inscricao: {
        required,
        maxLength: maxLength(15),
      },
      email1: {
        validaEmail: (email1, params) => IsEmailValido(email1, params),
      },
      telefone1: {
        required,
      },
      data_nascimento: {
        required,
        validaDataNascimento: (data_nascimento) => {
          let dataNascimento = moment(data_nascimento, "DD/MM/YYYY", true);
          if (dataNascimento.isValid()) {
            dataNascimento = moment().diff(dataNascimento, "years", false);
            if (dataNascimento < 18) return false;
            else return true;
          } else {
            return false;
          }
        },
      },
      genero: {
        required,
      },
      nome_mae_responsavel: {
        required,
      },
      razao_social: {
        required,
      },
      nome_fantasia: {
        required,
      },
    },
  },
  components: {
    TheMask,
  },
  created() {
    if (localStorage.getItem("redirecionar") == 1) {
      this.origemERP = 1;
    }

    this.indisponivel = false;
    this.isEmpresa = this.cliente.isEmpresa;
    this.endereco = this.contratacao.endereco;

    if (this.endereco.cep != null && this.endereco.cep != "") {
      this.buscouCep = true;

      // this.indisponivel = localStorage.getItem("indisponivel") === "true";

      this.disponivelCidade = true;
        // localStorage.getItem("disponivelCidade") === "true";

      if (this.endereco.cep.substring(this.endereco.cep.length - 3) == "000") {
        this.permiteAlterarEndereco = true;
      } else {
        this.permiteAlterarEndereco = false;
      }
    }

    if (localStorage.getItem("id_origem") != null) {
      this.id_origem = localStorage.getItem("id_origem");
    }



    // this.buscarTamanhosImoveis().then((data) => (this.tamanhosImoveis = data));
    // this.buscarTiposImoveis().then((data) => (this.tiposImoveis = data));
  },
  methods: {
    ...mapActions("home", [
      BUSCAR_CIDADES_DISPONIVEIS,
      BUSCAR_BAIRROS_DISPONIVEIS,
      ALTERAR_DADOS_CADASTRO,
    ]),
    ...mapActions("auth", [LOGOUT]),
    mensagemCumprimento,
    mascaraDocumento,
    retiraMascaraTelefone,
    formatarDataNascimento,
    logoutApp() {
      this[LOGOUT]().then(() => {
        let url = `/inicio`;

        if (this.$route.params.slug) {
          url = `/${this.$route.params.slug}`;
        }

        // this.$router.go(0);
        this.$router.push({ path: url, query: this.$route.query });
      });
    },
    async buscaCep() {
      // seta como submitted
      this.submitted = true;

      //sinaliza para os childs que foi feito o submit do form
      this.$v.endereco.cep.$touch();

      // valida se o campo é valido
      if (this.$v.endereco.cep.$invalid) {
        return;
      }

      // adiciona o loading no button
      loadingButton(this.$refs.btnBuscarCep, true);
      ApiService.get(`endereco/consultaCep?cep=${this.endereco.cep}`)
        .then(async ({ data: cidadeCep }) => {
          if (cidadeCep.street != "") {
            this.permiteAlterarEndereco = false;
          } else {
            this.permiteAlterarEndereco = true;
          }

          this.endereco = {
            ...this.endereco,
            cep: cidadeCep.zipCode,
            id_cidade: cidadeCep.id_cidade,
            logradouro: cidadeCep.street,
            bairro: cidadeCep.district,
            cidade: cidadeCep.city,
          };

          // altera as informacoes sobre o endereço
          await this.alterarDadosCadastro(null, null, {
            id_contratacao: this.contratacao.id_contratacao,
            cep: cidadeCep.zipCode,
            id_cidade: cidadeCep.id_cidade,
            logradouro: cidadeCep.street,
            bairro: cidadeCep.district,
            cidade: cidadeCep.city,
          });


          // localStorage.setItem("disponivelCidade", this.disponivelCidade);
          // localStorage.setItem("indisponivel", this.indisponivel);

          loadingButton(this.$refs.btnBuscarCep, false);
        })
        .catch(() => {
          this.$vToastify.warning(
            "Verifique se o cep está correto e tente novamente.",
            "Atenção"
          );
          loadingButton(this.$refs.btnBuscarCep, false);
        });
    },
    async buscarTamanhosImoveis() {
      return await ApiService.get("imoveis-tamanhos").then(({ data }) => {
        return data;
      });
    },
    async buscarTiposImoveis() {
      return await ApiService.get("imoveis-tipos").then(({ data }) => {
        return data;
      });
    },
    alterarDadosCadastro(campo, valor, valores = null) {
      if (valores != null) {
        if (typeof this.$route.query.fbtest !== "undefined") {
          valores.fbtest = this.$route.query.fbtest;
        }
        return this[ALTERAR_DADOS_CADASTRO](valores).then((dados) => {
          return dados;
        });
      } else {
        let erroValidacao = null;

        if (campo == "data_nascimento") {
          let dataNascimento = moment(valor, "DD/MM/YYYY", true).isValid();
          valor = dataNascimento
            ? moment(valor, "DD/MM/YYYY").format("YYYY-MM-DD")
            : "";
          erroValidacao = "Data nascimento inválida";
        } else if (campo == "email1") {
          if (!IsEmailValido(valor, true)) {
            valor = "";
            erroValidacao = "E-mail inválido";
          }
        } else if (campo == "rg_inscricao") {
          if (valor.length > 15) {
            valor = "";
            erroValidacao = "Documento inválido";
          }
        }

        if (erroValidacao) {
          this.$vToastify.warning(erroValidacao, "Atenção");
          return;
        }

        return this[ALTERAR_DADOS_CADASTRO]({
          [campo]: valor,
          id_contratacao: this.contratacao.id_contratacao,
        }).then((dados) => {
          if (campo === "numero") {
            if (dados.condominio_cadastrado) {
              this.endereco.condominio = dados.condominio_cadastrado.nome;
              this.endereco.bairro =
                dados.condominio_cadastrado.endereco.bairro.nome;
            }
          }
          this.$forceUpdate();
          return dados;
        });
      }
    },
    async alterarTodosCampos() {
      // if (!this.cliente.sem_email) {
      //   IsEmailValido(this.cliente.email1, true);
      // }
      // seta como submitted
      this.submitted = true;

      //sinaliza para os childs que foi feito o submit do form
      this.$v.$touch();

      // valida se o campo é valido
      if (this.$v.endereco.$invalid) {
        return;
      }

      if (
        this.endereco.condominio != "" &&
        this.endereco.apartamento == "" &&
        this.endereco.complemento == ""
      ) {
        this.$vToastify.warning(
          "Preencha o APARTAMENTO e BLOCO ou COMPLEMENTO, eles são obrigatórios para condomínio.",
          "Atenção"
        );
        return;
      }

      loadingButton(this.$refs.btnAlterarTodosCampos, true);
      await this.alterarDadosCadastro(null, null, {
        cep: this.endereco.cep,
        logradouro: this.endereco.logradouro,
        nome: this.endereco.nome,
        uf: this.endereco.cidade.estado.sigla,
        cidade: this.endereco.cidade.nome,
        id_cidade: this.endereco.id_cidade,
        bairro: this.endereco.bairro,
        numero: this.endereco.numero,
        complemento: this.endereco.complemento,
        bloco: this.endereco.bloco,
        apartamento: this.endereco.apartamento,
        condominio: this.endereco.condominio,
        id_contratacao: this.contratacao.id_contratacao,
        passo_formulario: DADOS_PESSOAIS,
      })
        .then(() => {
          this.$emit("evento-proximo-passo");
          loadingButton(this.$refs.btnAlterarTodosCampos, false);
        })
        .catch(() => {
          loadingButton(this.$refs.btnAlterarTodosCampos, false);
          this.$vToastify.warning(
            "Ocorreu um erro ao salvar os dados. Aguarde um instante e tente novamente.",
            "Atenção"
          );
        });
    },
    disableInputEmail() {
      // recebe o inverso do que está
      this.cliente.email1 = "";
      this.cliente.sem_email = !this.cliente.sem_email;
      this.submitted = false;
      this.$v.$touch();
      this.$forceUpdate();
    },
    IsWhatsApp() {
      this.numero_whatsapp = this.numero_whatsapp == 0 ? 1 : 0;
      this.alterarDadosCadastro("is_whatsapp", this.numero_whatsapp);
    },
    voltarPasso() {
      this.alterarDadosCadastro('passo_formulario', ENDERECO_EVENTO);
      this.$emit("evento-proximo-passo");
    }
  },
};
</script>

<style>
.div-endereco {
  margin-top: 3%;
}

.div-box-space {
  margin-top: 2%;
  height: 20px;
  width: 100%;
}

select {
  -webkit-appearance: button !important;
}
</style>
